import { call, put, } from 'redux-saga/effects'
import api from '../../services/api'
import ActionCreators from './actions'

export function* fetchCameras({ payload: { eventId }}) {
  try {
    const response = yield call(api.cameras.getAll, eventId)
    if (response && response.status === 200) {

      yield put(ActionCreators.fetchCamerasSuccess(response.data))
    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.fetchCamerasFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.fetchEventsFailure(message))
  }
}

export function* addCamera({ payload: { camera }}) {
  try {
    const response = yield call(api.cameras.createCamera, camera)
    if (response && response.status === 200) {

      yield put(ActionCreators.addCameraSuccess(response.data))
    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.addCameraFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.addCameraFailure(message))
  }
}

export function* deleteCamera({ payload: { cameraId }}) {
  try {
    const response = yield call(api.cameras.deleteCamera, cameraId)
    if (response && response.status === 200) {

      yield put(ActionCreators.deleteCameraSuccess(cameraId))
    } else {

      const message = response.data.message ? response.data.message : 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.deleteCameraFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.deleteCameraFailure(message))
  }
}

export function* updateCamera({ payload: { cameraId, camera }}) {
  try {
    const response = yield call(api.cameras.updateCamera, cameraId, camera)
    if (response && response.status === 200) {

      yield put(ActionCreators.updateCameraSuccess(response.data))
    } else {

      const message = response.data.message ? response.data.message : 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.updateCameraFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.updateCameraFailure(message))
  }
}



