import React, { Component } from 'react'
import styled from 'styled-components'
import { ReactComponent as CameraOff } from '../../assets/icons/videocam_off-24px.svg'
import { ReactComponent as CameraOn } from '../../assets/icons/videocam-24px.svg'
import { ReactComponent as MicOff } from '../../assets/icons/volume_off-24px.svg'
import { ReactComponent as MicOn } from '../../assets/icons/volume_up-24px.svg'

class ParticipantVideo extends Component {

  constructor(props) {
    super(props)
    this.mediaContainerRef = React.createRef()
    this.dataContainerRef = React.createRef()
    this.state = {
      audioMuted: false,
      videoMuted: false,
    }
    this.toggleAudioMute = this.toggleAudioMute.bind(this)
    this.toggleVideoMute = this.toggleVideoMute.bind(this)
    this.dataTrackListener = this.dataTrackListener.bind(this)
  }

  componentDidMount() {

    const { participant } = this.props
    const trackPublications = participant.tracks
    const mediaContainer = this.mediaContainerRef.current

    //console.log(trackPublications)

    // set up listeners for existing tracks
    trackPublications.forEach(publication => {

      if (publication.isSubscribed === false) {

        publication.on('subscribed', track => {
          if (publication.kind === "data") {
            publication.track.on('message',this.dataTrackListener)
          } else {
            const newTrack = publication.track.attach()
            mediaContainer.appendChild(newTrack)
            if (publication.kind === "video") {
              newTrack.addEventListener("resize", (e) => {
                const { videoWidth, videoHeight } = newTrack;
                console.log(videoWidth+"x"+videoHeight)
              }, false);
            }
            publication.track.on('enabled',() => {
              if (publication.kind === "audio")
                this.setState({audioMuted: false})
              if (publication.kind === "video")
                this.setState({videoMuted: false})
            })
            publication.track.on('disabled',() => {
              if (publication.kind === "audio")
                this.setState({audioMuted: true})
              if (publication.kind === "video")
                this.setState({videoMuted: true})
            })
          }
        });
      } else {

        if (publication.kind === "data") {
          publication.track.on('message',this.dataTrackListener)
        } else {
          const newTrack = publication.track.attach()
          mediaContainer.appendChild(newTrack)
          if (publication.kind === "video") {
            newTrack.addEventListener("resize", (e) => {
              const { videoWidth, videoHeight } = newTrack;
              console.log(videoWidth+"x"+videoHeight)
            }, false);
          }
          publication.track.on('enabled',() => {
            if (publication.kind === "audio")
              this.setState({audioMuted: false})
            if (publication.kind === "video")
              this.setState({videoMuted: false})
          })
          publication.track.on('disabled',() => {
            if (publication.kind === "audio")
              this.setState({audioMuted: true})
            if (publication.kind === "video")
              this.setState({videoMuted: true})
          })
        }
      }

      // set initial state for UI track control
      if (publication.kind === "audio")
        this.setState({audioMuted: !publication.isTrackEnabled})
      if (publication.kind === "video")
        this.setState({videoMuted: !publication.isTrackEnabled})

    })

    // listen to new tracks as they're published
    participant.on('trackPublished', publication => {

      if (publication.isSubscribed === false) {
        publication.on('subscribed', track => {
          if (publication.kind === "data") {
            publication.track.on('message',this.dataTrackListener)
          } else {
            const newTrack = publication.track.attach()
            mediaContainer.appendChild(newTrack)
            if (publication.kind === "video") {
              newTrack.addEventListener("resize", (e) => {
                const { videoWidth, videoHeight } = newTrack;
                console.log(videoWidth+"x"+videoHeight)
              }, false);
            }
            publication.track.on('enabled',() => {
              if (publication.kind === "audio")
                this.setState({audioMuted: false})
              if (publication.kind === "video")
                this.setState({videoMuted: false})
            })
            publication.track.on('disabled',() => {
              if (publication.kind === "audio")
                this.setState({audioMuted: true})
              if (publication.kind === "video")
                this.setState({videoMuted: true})
            })
          }
        })
      } else {
        if (publication.kind === "data") {
          publication.track.on('message',this.dataTrackListener)
        } else {
          const newTrack = publication.track.attach()
          mediaContainer.appendChild(newTrack)
          if (publication.kind === "video") {
            newTrack.addEventListener("resize", (e) => {
              const { videoWidth, videoHeight } = newTrack;
              console.log(videoWidth+"x"+videoHeight)
            }, false);
          }
          publication.track.on('enabled',() => {
            if (publication.kind === "audio")
              this.setState({audioMuted: false})
            if (publication.kind === "video")
              this.setState({videoMuted: false})
          })
          publication.track.on('disabled',() => {
            if (publication.kind === "audio")
              this.setState({audioMuted: true})
            if (publication.kind === "video")
              this.setState({videoMuted: true})
          })
        }
      }
    })

  }

  componentDidUpdate(prevProps) {
    // if localData prop is supplied, treat like we're receiving a socket message
    // this is a workaround for a lack of LocalDataTrack events
    if (this.props.localData && (this.props.localData !== prevProps.localData)) {
      this.dataTrackListener(this.props.localData)
    }
  }

  dataTrackListener(data) {
    // this listener is configured to grab the data emitted for THIS PARTICIPANT
    // good for user-specific data, not room broadcast


    // intercept for optional data callback to bring the data up to the room component
    if (this.props.dataCallback)
      this.props.dataCallback(data)
  }

  toggleAudioMute() {
    if (this.props.moderatorAVControl) {
      // pass up to moderator control
      this.props.moderatorAVControl({audioMuted: !this.state.audioMuted, participant: this.props.participant.sid})
    } else {
      // local control
      const tracks = this.props.participant.tracks
      tracks.forEach(publication => {
        if (publication.kind && publication.kind === "audio") {
          if (this.state.audioMuted)
            publication.track.enable()
          else
            publication.track.disable()
        }
      })
      this.setState({audioMuted: !this.state.audioMuted})
    }
  }

  toggleVideoMute() {
    if (this.props.moderatorAVControl) {
      // pass up to moderator control
      this.props.moderatorAVControl({videoMuted: !this.state.videoMuted, participant: this.props.participant.sid})
    } else {
      const tracks = this.props.participant.tracks
      tracks.forEach(publication => {
        if (publication.kind && publication.kind === "video") {
          if (this.state.videoMuted)
            publication.track.enable()
          else
            publication.track.disable()
        }
      })
      this.setState({videoMuted: !this.state.videoMuted})
    }
  }

  render() {

    const { audioOnly, hideTitle, title } = this.props
    const { videoMuted, audioMuted } = this.state

    if (audioOnly)
      return (
        <div style={{display: 'none'}} ref={this.mediaContainerRef} />
      )

    const displayAVControls = (this.props.isLocalParticipant === true || this.props.moderatorAVControl)
    const audioControl = audioMuted
                  ? <MicOff alt="Click to un-mute" onClick={this.toggleAudioMute} style={{fill: 'red', zIndex: 10, cursor: 'pointer'}} />
                  : <MicOn alt="Click to mute" onClick={this.toggleAudioMute} style={{fill: 'white', zIndex: 10, cursor: 'pointer'}} />
    const videoControl = videoMuted
                  ? <CameraOff onClick={this.toggleVideoMute} style={{fill: 'red', zIndex: 10, cursor: 'pointer'}} />
                  : <CameraOn onClick={this.toggleVideoMute} style={{fill: 'white', zIndex: 10, cursor: 'pointer'}} />
    return(

      <ParticipantVideoComponent>

        { (displayAVControls) &&
          <div style={{height: '40px', padding: '0 5px 0 5px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', zIndex: 10, backgroundColor: 'rgba(0,0,0,0.5)' }}>
            { displayAVControls &&
              <div style={{display: 'flex', flexDirection: 'row'}}>
                { videoControl }
                { audioControl }
              </div>
            }
          </div>
        }

        <StyledMediaContainer videoMuted={videoMuted} ref={this.mediaContainerRef} onClick={this.props.onClick ? this.props.onClick : null} topBar={displayAVControls ? '-40px' : 0} bottomBar={hideTitle ? 0 : '-30px'}/>

        { videoMuted &&
          <MutedVideoPlaceholder>
            <CameraOff style={{marginTop: '60px', fill: 'rgba(255,255,255,0.1', transform: 'scale(3)'}} />
            <span>(video not available)</span>
          </MutedVideoPlaceholder>
        }

         { (hideTitle !== true) &&
          <TitleContainer height={'30px'}>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            {title}
            </div>
          </TitleContainer>
        }

      </ParticipantVideoComponent>
    )
  }

}

const ParticipantVideoComponent = styled.div`
  width: 100%;
  height: 100%;
  max-height: inherit;
  max-width: inherit;
  min-width: inherit;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

const StyledMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${props => props.topBar} 0 ${props => props.bottomBar} 0;
  flex: 1;
  max-height: inherit;
  max-width: inherit;
  min-width: inherit;
  min-height: inherit;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  overflow: hidden;
  position: relative;

  video {
    display: ${props => props.videoMuted ? 'none' : 'block'};
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
  }
`;

const MutedVideoPlaceholder = styled.div`
  width: inherit;
  height: inherit;

  background-color: #282c34;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    font-size: 12px;
    color: white;
    margin-top: 20px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${props => props.height};
  z-index: 10;
  padding: 0 0 0 10px;
  background-color: rgba(0,0,0,0.5);
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
`;


export default ParticipantVideo