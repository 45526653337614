import axios from 'axios'
import Api from './Api'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    'Cache-Control': 'no-cache',
  },
  timeout: 30000,
})

export default Api(api)
