import React from 'react';
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      console.log("Player ready")
    });

    this.player.on('durationchange',(event) => {
      console.log("Duration change detected")

      const player = this.player
      var autoplay = player.play()

      if (autoplay !== undefined) {
        autoplay.then(function() {
          console.log("Autoplay started")
          player.liveTracker.seekToLiveEdge()
        }).catch(function(error) {
          console.log("Autoplay prevented, muting, retrying")
          player.muted(true)
          player.play().then(player.liveTracker.seekToLiveEdge())
        });
      }

    })

  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {

    return (
      <div style={{width: '100%', height: '100%', border: '1px solid white'}}>
        <div data-vjs-player>
          <video ref={ node => this.videoNode = node } className="video-js"></video>
        </div>
      </div>
    )
  }
}