import AuthActions from '../../modules/auth/actions'
import ApiInstance from '../api'

let apiCalls = []
let refreshingToken = false

export const InterceptorMiddleware = (api) => {

  // this prevents interceptor from executing on every store update in the call
  let interceptorsBound = false

  return store => next => async action => {

    if (!interceptorsBound) {
      interceptorsBound = true
      api.interceptors.response.use((response) => {

        // normal ok request, pass through
        return response

      }, async error => {

        let errorResponse = error.response
        const originalRequest = error.config
        const { auth } = store.getState()

        if (errorResponse) {

          if (errorResponse.status === 401 && auth.refresh_token) {
            // attempt token refresh


            if (!refreshingToken) {
              refreshingToken = true

              ApiInstance.auth.refreshToken(auth.refresh_token, auth.email).then(responseToken => {

                const results = responseToken.data.data.AuthenticationResult

                // api default token is updated in reducer
                store.dispatch(AuthActions.updateToken(
                  results.AccessToken,
                  results.IdToken
                ))

                refreshingToken = false
                apiCalls.filter(apiCall => apiCall(results.AccessToken))
              })

            }

            const retryRequest = new Promise((resolve) => {
              apiCalls.push((token) => {
                originalRequest.headers.Authorization = 'Bearer ' + token
                resolve(api(originalRequest))
              })
            })
            return retryRequest
          }


          if (errorResponse.status === 403) {
            // automatic logout on 403 forbidden
            store.dispatch(AuthActions.logout())
          }

          return Promise.reject(error)

        }

        // reject for all other errors that dont have a response
        return Promise.reject(error)

      })
    }

    next(action)

  }


}