import { connect } from 'react-redux'
import OrgManagementScreenView from '../components/OrgManagementScreenView'
import OrgActions from '../../../modules/orgs/actions'
import InviteActions from '../../../modules/invites/actions'

export const mapStateToProps = ({ auth, orgs, invites }) => {
  return {
    authdEmail: auth.email,
    fetching: orgs.details_fetching,
    updateFetching: orgs.update_fetching,
    deleteFetching: orgs.delete_fetching,
    inviteCreateFetching: invites.create_fetching,
    inviteResendFetching: invites.resend_fetching,
    inviteRevokeFetching: invites.revoke_fetching,
    updateOrgMemberFetching: orgs.update_member_fetching,
    deleteOrgMemberFetching: orgs.delete_member_fetching,
    error: orgs.details_error,
    updateError: orgs.update_error,
    deleteError: orgs.delete_error,
    inviteCreateError: invites.create_error,
    inviteResendError: invites.resend_error,
    inviteRevokeError: invites.revoke_error,
    updateOrgMemberError: orgs.update_member_error,
    deleteOrgMemberError: orgs.delete_member_error,
    orgDetails: orgs.org_details,
    orgs: orgs.org_list,
    selectedOrg: orgs.selected_org,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  fetchOrgs: () => dispatch(OrgActions.fetchOrgsRequest()),
  fetchOrgDetails: (orgId) => dispatch(OrgActions.orgDetailsRequest(orgId)),
  updateOrg: (orgId,name) => dispatch(OrgActions.updateOrgRequest(orgId,name)),
  deleteOrg: (orgId) => dispatch(OrgActions.deleteOrgRequest(orgId)),
  sendInvite: (orgId,email,role) => dispatch(InviteActions.orgInviteRequest(orgId,email,role)),
  resendInvite: (inviteId) => dispatch(InviteActions.resendInviteRequest(inviteId)),
  revokeInvite: (inviteId) => dispatch(InviteActions.revokeInviteRequest(inviteId)),
  updateOrgMember: (orgId,userId,role) => dispatch(OrgActions.updateOrgMemberRequest(orgId,userId,role)),
  deleteOrgMember: (orgId,userId) => dispatch(OrgActions.deleteOrgMemberRequest(orgId,userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrgManagementScreenView)