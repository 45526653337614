import Immutable from 'seamless-immutable'
import * as EventActionTypes from './actions'

/* ------------- Initial State ------------- */
const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  event: null,
})

/* ------------- Reducer ------------- */
export function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    case EventActionTypes.FETCH_EVENT_REQUEST:
      return state.merge({
        fetching: true,
        error: null,
      })

    case EventActionTypes.FETCH_EVENT_SUCCESS:
      return state.merge({
        event: payload.event,
        fetching: false,
      })

    case EventActionTypes.FETCH_EVENT_FAILURE:
      return state.merge({
        fetching: false,
        error: payload.error,
      })

    case EventActionTypes.DESELECT_EVENT:
      return state.merge({
        event: null
      })

    default:
      return state

  }
}