import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import StackLogo from '../../../assets/images/Stack_Sports_logo_41.png'

class AuthRedirectScreen extends Component {

  componentDidMount() {

    const { fetching, tokenAuth } = this.props

    if (!fetching) {
      const search = new URLSearchParams(this.props.location.search)
      const idToken = search.get("id_token")
      const accessToken = search.get("access_token")
      const refreshToken = search.get("refresh_token")

      if (idToken && accessToken && idToken) {
        const decoded = jwt.decode(idToken, {complete: true})
        if (decoded.payload && decoded.payload.email)
          tokenAuth(decoded.payload.email,idToken,accessToken,refreshToken)
      }
    }
  }

  componentDidUpdate(prevProps) {

    if (!this.props.error && !prevProps.accessToken && this.props.accessToken) {
      // authentication is complete
      if (this.props.existingUser) {
        this.props.history.replace("/")
      } else {
        this.props.history.replace("/signup")
      }

    }

  }

  render() {

    const { fetching, error } = this.props

    return(
      <MainContainer>
        <img style={{marginRight: '10px'}} src={StackLogo} alt="stack logo" />
        <div style={{fontSize: '20px', fontWeight: 'bold', paddingTop: '20px'}}>StackStream MVP Demo</div>
        <div>Internal Use Only - v{process.env.REACT_APP_VERSION}</div>

        <div style={{marginTop: '20px'}}>


          { fetching &&
            <div style={{display: 'flex', flexDirection: 'column', fontSize: '20px', fontWeight: 'bold'}}>
              Authenticating...
              <Spinner animation="border" style={{color: '#FFF', alignSelf: 'center', marginTop: '10px'}} />
            </div>
          }

          { error &&
            <div>{error}</div>
          }

        </div>


      </MainContainer>
    )
  }

}

const MainContainer = Styled.div`
  color: #FFF;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export default withRouter(AuthRedirectScreen)