export const ORG_INVITE_REQUEST = 'ORG_INVITE_REQUEST'
export const ORG_INVITE_SUCCESS = 'ORG_INVITE_SUCCESS'
export const ORG_INVITE_FAILURE = 'ORG_INVITE_FAILURE'
export const RESEND_INVITE_REQUEST = 'RESEND_INVITE_REQUEST'
export const RESEND_INVITE_SUCCESS = 'RESEND_INVITE_SUCCESS'
export const RESEND_INVITE_FAILURE = 'RESEND_INVITE_FAILURE'
export const REVOKE_INVITE_REQUEST = 'REVOKE_INVITE_REQUEST'
export const REVOKE_INVITE_SUCCESS = 'REVOKE_INVITE_SUCCESS'
export const REVOKE_INVITE_FAILURE = 'REVOKE_INVITE_FAILURE'
export const ACCEPT_INVITE_REQUEST = 'ACCEPT_INVITE_REQUEST'
export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS'
export const ACCEPT_INVITE_FAILURE = 'ACCEPT_INVITE_FAILURE'
export const DECLINE_INVITE_REQUEST = 'DECLINE_INVITE_REQUEST'
export const DECLINE_INVITE_SUCCESS = 'DECLINE_INVITE_SUCCESS'
export const DECLINE_INVITE_FAILURE = 'DECLINE_INVITE_FAILURE'

export default {

  orgInviteRequest: (orgId,email,role) => ({
    type: ORG_INVITE_REQUEST,
    payload: {
      orgId,
      email,
      role,
    },
  }),

  orgInviteSuccess: () => ({
    type: ORG_INVITE_SUCCESS,
  }),

  orgInviteFailure: (error) => ({
    type: ORG_INVITE_FAILURE,
    payload: {
      error,
    },
  }),

  resendInviteRequest: (inviteId) => ({
    type: RESEND_INVITE_REQUEST,
    payload: {
      inviteId
    }
  }),

  resendInviteSuccess: (inviteId,invite) => ({
    type: RESEND_INVITE_SUCCESS,
    payload: {
      inviteId,
      invite
    }
  }),

  resendInviteFailure: (inviteId,error) => ({
    type: RESEND_INVITE_FAILURE,
    payload: {
      inviteId,
      error,
    },
  }),

  revokeInviteRequest: (inviteId) => ({
    type: REVOKE_INVITE_REQUEST,
    payload: {
      inviteId
    }
  }),

  revokeInviteSuccess: (inviteId) => ({
    type: REVOKE_INVITE_SUCCESS,
    payload: {
      inviteId
    }
  }),

  revokeInviteFailure: (inviteId,error) => ({
    type: REVOKE_INVITE_FAILURE,
    payload: {
      inviteId,
      error,
    },
  }),

  acceptInviteRequest: (email,code) => ({
    type: ACCEPT_INVITE_REQUEST,
    payload: {
      email,
      code,
    }
  }),

  acceptInviteSuccess: (code,org) => ({
    type: ACCEPT_INVITE_SUCCESS,
    payload: {
      code,
      org,
    },
  }),

  acceptInviteFailure: (code,error) => ({
    type: ACCEPT_INVITE_FAILURE,
    payload: {
      code,
      error,
    },
  }),

  declineInviteRequest: (email,code) => ({
    type: DECLINE_INVITE_REQUEST,
    payload: {
      email,
      code,
    }
  }),

  declineInviteSuccess: (code,invite) => ({
    type: DECLINE_INVITE_SUCCESS,
    payload: {
      code,
      invite,
    },
  }),

  declineInviteFailure: (code,error) => ({
    type: DECLINE_INVITE_FAILURE,
    payload: {
      code,
      error,
    },
  }),


}