import { api, } from '../../services/api'
import Immutable from 'seamless-immutable'
import * as AuthActionTypes from './actions'

/* ------------- Initial State ------------- */
const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  video_fetching: false,
  video_error: null,
  access_token: null,
  refresh_token: null,
  id_token: null,
  video_service_identity: null,
  video_token: null,
  signup_fetching: false,
  signup_error: null,
  email: null,
  sso_status: false,
  existing_user: false,
  validate_fetching: false,
  validate_error: null,
  email_validated: null,
  resend_validation_fetching: false,
  resend_validation_error: null,
  register_fetching: false,
  register_error: null,
  forgot_pwd_fetching: false,
  forgot_pwd_error: null,
  change_pwd_fetching: false,
  change_pwd_error: null,
})

/* ------------- Reducer ------------- */
export function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    case AuthActionTypes.AUTH_REQUEST:
    case AuthActionTypes.TOKEN_AUTH_REQUEST:
      return state.merge({
        fetching: true,
        email: payload.email,
        error: null,
        email_validated: false,
        access_token: null,
        refresh_token: null,
        id_token: null,
      })

    case AuthActionTypes.REGISTER_SUCCESS:
    case AuthActionTypes.AUTH_SUCCESS:
    case AuthActionTypes.TOKEN_AUTH_SUCCESS:
      return state.merge({
        fetching: false,
        register_fetching: false,
        sso_status: true,
        existing_user: payload.existing_user,
        email_validated: payload.email_validated,
        access_token: payload.access_token ? payload.access_token : state.access_token,
        refresh_token: payload.refresh_token ? payload.refresh_token : state.refresh_token,
        id_token: payload.id_token ? payload.id_token : state.id_token,
      })

    case AuthActionTypes.AUTH_FAILURE:
    case AuthActionTypes.TOKEN_AUTH_FAILURE:
      return state.merge({
        fetching: false,
        error: payload.error,
      })

    case AuthActionTypes.CLEAR_SIGNUP:
    case AuthActionTypes.LOGOUT:
      return INITIAL_STATE

    case AuthActionTypes.VIDEO_AUTH_REQUEST:
      return state.merge({
        video_fetching: true,
        video_error: null,
      })

    case AuthActionTypes.VIDEO_AUTH_SUCCESS:
      return state.merge({
        video_fetching: false,
        video_service_identity: payload.video_service_identity,
        video_token: payload.video_token,
      })

    case AuthActionTypes.VIDEO_AUTH_FAILURE:
      return state.merge({
        video_fetching: false,
        video_error: payload.error,
      })

    case AuthActionTypes.SIGNUP_CHECK_REQUEST:
      return state.merge({
        signup_fetching: true,
        email: payload.email,
        sso_status: false,
      })

    case AuthActionTypes.SIGNUP_CHECK_SUCCESS:
      return state.merge({
        signup_fetching: false,
        sso_status: payload.sso_status,
      })

    case AuthActionTypes.SIGNUP_CHECK_FAILURE:
      return state.merge({
        signup_fetching: false,
        signup_error: payload.error,
      })

    case AuthActionTypes.REGISTER_REQUEST:
      return state.merge({
        register_fetching: true,
        register_error: null,
      })

    case AuthActionTypes.REGISTER_FAILURE:
      return state.merge({
        register_fetching: false,
        register_error: payload.error,
      })

    case AuthActionTypes.VALIDATE_EMAIL_REQUEST:
      return state.merge({
        validate_fetching: true,
        validate_error: null,
      })

    case AuthActionTypes.VALIDATE_EMAIL_SUCCESS:
      return state.merge({
        validate_fetching: false,
        email_validated: payload.validated,
      })

    case AuthActionTypes.VALIDATE_EMAIL_FAILURE:
      return state.merge({
        validate_fetching: false,
        validate_error: payload.error,
      })

    case AuthActionTypes.RESEND_VALIDATION_EMAIL_REQUEST:
      return state.merge({
        resend_validation_fetching: true,
        resend_validation_error: null
      })

    case AuthActionTypes.RESEND_VALIDATION_EMAIL_SUCCESS:
      return state.merge({
        resend_validation_fetching: false,
        resend_validation_error: null
      })

    case AuthActionTypes.RESEND_VALIDATION_EMAIL_FAILURE:
      return state.merge({
        resend_validation_fetching: false,
        resend_validation_error: payload.error
      })

    case AuthActionTypes.FORGOT_PWD_REQUEST:
      return state.merge({
        forgot_pwd_fetching: true,
        forgot_pwd_error: null
      })

    case AuthActionTypes.FORGOT_PWD_SUCCESS:
      return state.merge({
        forgot_pwd_fetching: false,
        forgot_pwd_error: null
      })

    case AuthActionTypes.FORGOT_PWD_FAILURE:
      return state.merge({
        forgot_pwd_fetching: false,
        forgot_pwd_error: payload.error
      })

    case AuthActionTypes.CHANGE_PWD_REQUEST:
      return state.merge({
        change_pwd_fetching: true,
        change_pwd_error: null
      })

    case AuthActionTypes.CHANGE_PWD_SUCCESS:
      return state.merge({
        change_pwd_fetching: false,
        change_pwd_error: null
      })

    case AuthActionTypes.CHANGE_PWD_FAILURE:
      return state.merge({
        change_pwd_fetching: false,
        change_pwd_error: payload.error
      })

    case AuthActionTypes.UPDATE_TOKEN:
      api.defaults.headers.common.Authorization = `Bearer ${payload.accessToken}`
      return state.merge({
        access_token: payload.accessToken,
        id_token: payload.idToken,
      })

    default:
      return state

  }
}