import React, { Component, Fragment } from 'react'
import Styled from 'styled-components'
import { Dropdown, DropdownButton, Button, Spinner } from 'react-bootstrap'
import StackLogo from '../../assets/images/Stack_Sports_logo_41.png'
import { Check } from 'react-bootstrap-icons'

class TitleHeader extends Component {

  render() {

    const {
      selectedEvent,
      handleLogout,
      handlePwdChange,
      handleManageOrg,
      handleAddOrg,
      email,
      orgList = [],
      inviteList = [],
      handleSelectOrg,
      handleAcceptInvite,
      handleDeclineInvite,
      acceptDeclineFetching,
      selectedOrg
    } = this.props

    const selectedOrgFromList = orgList.find((org) => org.id === selectedOrg)
    const userRole = selectedOrgFromList ? selectedOrgFromList.role : "USER"

    return(
      <div style={{width: '100%'}}>
      <HeaderContainer>
        <img alt="Stack Logo" src={StackLogo} style={{width: '30%', maxWidth: '239px'}} />
        { selectedEvent
          ? <div style={{color: '#FFF', display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
              <div style={{fontSize: '18px', fontWeight: 'bold'}}>{selectedEvent.name}</div>
              <div style={{fontSize: '14px'}}>{selectedEvent.location}</div>
            </div>
          : <div style={{color: '#FFF', display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
              <div style={{display: 'flex', flexDirection: 'row', position: 'relative'}}>
              { (inviteList.length > 0) &&
                <AlertBadge>
                  {inviteList.length}
                </AlertBadge>
              }
              <DropdownButton variant="secondary" title={email} alignRight>
                { (orgList.length > 0) &&
                  <Fragment>
                  { orgList.map((org) => {
                      return (
                        <Dropdown.Item key={org.id} as="button" onClick={() => handleSelectOrg(org.id)}>
                          {org.name}
                          {(org.id === selectedOrg) &&
                            <Check color="green" size={18} />
                          }
                        </Dropdown.Item>
                      )
                    })
                  }
                  <Dropdown.Divider />
                  </Fragment>
                }
                { (inviteList.length > 0) &&
                  <Fragment>
                  { inviteList.map((invite) => {
                      const inviteFetching = acceptDeclineFetching.includes(invite.invite_code)
                      return (
                        <Dropdown.Item key={invite.id}>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                          {invite.org_name}
                          <div style={{width: '30px', display: 'flex', flexDrection: 'row', justifyContent: 'center'}}>
                            { inviteFetching &&
                                <Spinner as="span" animation="border" size="sm" />
                            }
                          </div>
                          <Button variant="success" size="sm" style={{margin: '0 5px 0 5px'}} onClick={() => handleAcceptInvite(invite.email, invite.invite_code)} disabled={inviteFetching}>Accept</Button>
                          <Button variant="danger" size="sm" onClick={() => handleDeclineInvite(invite.email, invite.invite_code)} disabled={inviteFetching}>Decline</Button>
                          </div>
                        </Dropdown.Item>
                      )
                    })
                  }
                  <Dropdown.Divider />
                  </Fragment>
                }
                { (userRole === "ADMIN") && handleManageOrg && <Dropdown.Item as="button" onClick={handleManageOrg}>Manage Org</Dropdown.Item> }
                { handleAddOrg && <Dropdown.Item as="button" onClick={handleAddOrg}>Create New Org</Dropdown.Item> }
                { handlePwdChange && <Dropdown.Item as="button" onClick={handlePwdChange}>Change Password</Dropdown.Item> }
                { handleLogout && <Dropdown.Item as="button" onClick={handleLogout}>Log Out</Dropdown.Item> }
              </DropdownButton>
              </div>
            </div>
        }
      </HeaderContainer>
      </div>
    )
  }
}

const HeaderContainer = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #000;
  padding: 10px;
  -webkit-flex: 0;
  flex-shrink: 0;
`

const AlertBadge = Styled.div`
  border: 2px solid white;
  background-color: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  left: -10px;
  top: -10px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
`

export default TitleHeader