export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST'
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE'
export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST'
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS'
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE'
export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE'
export const CLEAR_EVENTS = 'CLEAR_EVENTS'

export default {

  fetchEventsRequest: (orgId) => ({
    type: FETCH_EVENTS_REQUEST,
    payload: {
      orgId,
    },
  }),

  fetchEventsSuccess: (events) => ({
    type: FETCH_EVENTS_SUCCESS,
    payload: {
      events,
    },
  }),

  fetchEventsFailure: (error) => ({
    type: FETCH_EVENTS_FAILURE,
    payload: {
      error,
    },
  }),

  addEventRequest: (event) => ({
    type: ADD_EVENT_REQUEST,
    payload: {
      event,
    }
  }),

  addEventSuccess: (event) => ({
    type: ADD_EVENT_SUCCESS,
    payload: {
      event,
    }
  }),

  addEventFailure: (error) => ({
    type: ADD_EVENT_FAILURE,
    payload: {
      error,
    },
  }),

  deleteEventRequest: (eventId) => ({
    type: DELETE_EVENT_REQUEST,
    payload: {
      eventId,
    }
  }),

  deleteEventSuccess: () => ({
    type: DELETE_EVENT_SUCCESS,
    payload: {
    }
  }),

  deleteEventFailure: (error) => ({
    type: DELETE_EVENT_FAILURE,
    payload: {
      error,
    },
  }),

  clearEvents: () => ({
    type: CLEAR_EVENTS
  })

}