import React, { Fragment } from 'react';
import { Card, Button, Spinner } from 'react-bootstrap'
import moment from 'moment'

function EventListItem(props) {

  const { name, location, start_time, end_time, camera_count, stream_status, disabled, working } = props
  let formattedTime = null
  if (start_time)
    formattedTime = moment(start_time).format('dddd MMMM Do YYYY, h:mma')
  if (end_time)
    formattedTime += "-"+moment(end_time).format('h:mma')

  return (
    <Card style={{width: '100%', marginBottom: '5px'}}>
      <Card.Body>
        <Card.Title>{name}</Card.Title>

          <div style={{margin: '0 0 20px 0'}}>
          {formattedTime && <div><span style={{fontWeight: 'bold'}}>When:</span> {formattedTime}</div>}
          {(location !== '') && <div><span style={{fontWeight: 'bold'}}>Where:</span> {location}</div>}
          <div><span style={{fontWeight: 'bold'}}>Cameras:</span> {camera_count}</div>
          <div><span style={{fontWeight: 'bold'}}>Stream Status:</span> {stream_status}</div>
          </div>

        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <Button variant="danger" onClick={props.onRemove} disabled={disabled || stream_status !== "STOPPED"}>Remove</Button>
          <Button variant="success" onClick={props.onSelect} disabled={disabled}>
            {working
              ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Loading...</Fragment>
              : "Select"
            }
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default EventListItem;
