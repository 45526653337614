import React, { Component, Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import Styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import StackLogo from '../../../assets/images/Stack_Sports_logo_41.png'

class VerifyEmailScreen extends Component {

  componentDidMount() {

    const { fetching, validated, validateEmail } = this.props

    if (!fetching && !validated ) {
      const search = new URLSearchParams(this.props.location.search)
      const email = search.get("email")
      const code = search.get("code")
      if (email && code) {
        validateEmail(email,code)
      }
    }

  }

  render() {

    const { fetching, error, validated, accessToken } = this.props

    return(
      <MainContainer>
        <img style={{marginRight: '10px'}} src={StackLogo} alt="stack logo" />
        <div style={{fontSize: '20px', fontWeight: 'bold', paddingTop: '20px'}}>StackStream MVP Demo</div>
        <div>Internal Use Only - v{process.env.REACT_APP_VERSION}</div>

        <div style={{marginTop: '20px'}}>


          { fetching &&
            <div style={{display: 'flex', flexDirection: 'column', fontSize: '20px', fontWeight: 'bold'}}>
              Verifying email...
              <Spinner animation="border" style={{color: '#FFF', alignSelf: 'center', marginTop: '10px'}} />
            </div>
          }

          { error &&
            <div>{error}</div>
          }

          { validated &&
            <Fragment>
              { accessToken
              ? <Redirect
                  to={{
                    pathname: "/"
                  }}
                />
              : <Redirect
                  to={{
                    pathname: "/login",
                    search: "?validated=true"
                  }}
                />
              }
            </Fragment>
          }

        </div>


      </MainContainer>
    )
  }

}

const MainContainer = Styled.div`
  color: #FFF;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export default withRouter(VerifyEmailScreen)