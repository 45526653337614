import Immutable from 'seamless-immutable'
import * as StartupActionTypes from './actions'

/* ------------- Initial State ------------- */
const INITIAL_STATE = Immutable({
  loaded: false,
  rehydrated: false,
})

/* ------------- Reducer ------------- */
export function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case StartupActionTypes.STARTUP:
      return state.merge({
        loaded: false,
      })

    case StartupActionTypes.STARTUP_FINISHED:
      return state.merge({
        loaded: true,
      })

    case StartupActionTypes.REHYDRATION_FINISHED:
      return state.merge({
        rehydrated: true,
      })

    default:
      return state
  }
}
