/* ------------- Action Types ----------- */
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const TOKEN_AUTH_REQUEST = 'TOKEN_AUTH_REQUEST'
export const TOKEN_AUTH_SUCCESS = 'TOKEN_AUTH_SUCCESS'
export const TOKEN_AUTH_FAILURE = 'TOKEN_AUTH_FAILURE'
export const LOGOUT = 'LOGOUT'
export const VIDEO_AUTH_REQUEST = 'VIDEO_AUTH_REQUEST'
export const VIDEO_AUTH_SUCCESS = 'VIDEO_AUTH_SUCCESS'
export const VIDEO_AUTH_FAILURE = 'VIDEO_AUTH_FAILURE'

export const CLEAR_SIGNUP = 'CLEAR_SIGNUP'
export const SIGNUP_CHECK_REQUEST = 'SIGNUP_CHECK_REQUEST'
export const SIGNUP_CHECK_SUCCESS = 'SIGNUP_CHECK_SUCCESS'
export const SIGNUP_CHECK_FAILURE = 'SIGNUP_CHECK_FAILURE'
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'

export const VALIDATE_EMAIL_REQUEST = 'VALIDATE_EMAIL_REQUEST'
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS'
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE'
export const RESEND_VALIDATION_EMAIL_REQUEST = 'RESEND_VALIDATION_EMAIL_REQUEST'
export const RESEND_VALIDATION_EMAIL_SUCCESS = 'RESEND_VALIDATION_EMAIL_SUCCESS'
export const RESEND_VALIDATION_EMAIL_FAILURE = 'RESEND_VALIDATION_EMAIL_FAILURE'

export const FORGOT_PWD_REQUEST = 'FORGOT_PWD_REQUEST'
export const FORGOT_PWD_SUCCESS = 'FORGOT_PWD_SUCCESS'
export const FORGOT_PWD_FAILURE = 'FORGOT_PWD_FAILURE'
export const CHANGE_PWD_REQUEST = 'CHANGE_PWD_REQUEST'
export const CHANGE_PWD_SUCCESS = 'CHANGE_PWD_SUCCESS'
export const CHANGE_PWD_FAILURE = 'CHANGE_PWD_FAILURE'

export const UPDATE_TOKEN = 'UPDATE_TOKEN'


/* ------------- Action Creators ------------- */
export default {
  authRequest: (email, password) => ({
    type: AUTH_REQUEST,
    payload: {
      email,
      password,
    },
  }),

  authSuccess: (existing_user, email_validated, access_token, refresh_token, id_token) => ({
    type: AUTH_SUCCESS,
    payload: {
      existing_user,
      email_validated,
      access_token,
      refresh_token,
      id_token,
    },
  }),

  authFailure: (error) => ({
    type: AUTH_FAILURE,
    payload: {
      error,
    },
  }),

  tokenAuthRequest: (email, idToken, accessToken, refreshToken) => ({
    type: TOKEN_AUTH_REQUEST,
    payload: {
      email,
      idToken,
      accessToken,
      refreshToken,
    },
  }),

  tokenAuthSuccess: (existing_user, email_validated, access_token, refresh_token, id_token) => ({
    type: TOKEN_AUTH_SUCCESS,
    payload: {
      existing_user,
      email_validated,
      access_token,
      refresh_token,
      id_token,
    },
  }),

  tokenAuthFailure: (error) => ({
    type: TOKEN_AUTH_FAILURE,
    payload: {
      error,
    },
  }),

  logout: () => ({
    type: LOGOUT,
  }),

  videoAuthRequest: () => ({
    type: VIDEO_AUTH_REQUEST,
  }),

  videoAuthSuccess: (video_service_identity, video_token) => ({
    type: VIDEO_AUTH_SUCCESS,
    payload: {
      video_service_identity,
      video_token,
    },
  }),

  videoAuthFailure: (error) => ({
    type: VIDEO_AUTH_FAILURE,
    payload: {
      error,
    },
  }),

  clearSignup: () => ({
    type: CLEAR_SIGNUP
  }),

  signupCheckRequest: (email) => ({
    type: SIGNUP_CHECK_REQUEST,
    payload: {
      email,
    },
  }),

  signupCheckSuccess: (sso_status) => ({
    type: SIGNUP_CHECK_SUCCESS,
    payload: {
      sso_status,
    },
  }),

  signupCheckFailure: (error) => ({
    type: SIGNUP_CHECK_FAILURE,
    payload: {
      error,
    },
  }),

  registerRequest: (email,password,firstName,lastName,token,betaCode) => ({
    type: REGISTER_REQUEST,
    payload: {
      email,
      password,
      firstName,
      lastName,
      token,
      betaCode,
    },
  }),

  registerSuccess: (existing_user, email_validated, access_token, refresh_token, id_token) => ({
    type: REGISTER_SUCCESS,
    payload: {
      existing_user,
      email_validated,
      access_token,
      refresh_token,
      id_token,
    },
  }),

  registerFailure: (error) => ({
    type: REGISTER_FAILURE,
    payload: {
      error,
    },
  }),

  validateEmailRequest: (email,validationCode) => ({
    type: VALIDATE_EMAIL_REQUEST,
    payload: {
      email,
      validationCode,
    }
  }),

  validateEmailSuccess: (validated) => ({
    type: VALIDATE_EMAIL_SUCCESS,
    payload: {
      validated,
    }
  }),

  validateEmailFailure: (error) => ({
    type: VALIDATE_EMAIL_FAILURE,
    payload: {
      error,
    }
  }),

  resendValidationEmailRequest: (email) => ({
    type: RESEND_VALIDATION_EMAIL_REQUEST,
    payload: {
      email,
    }
  }),

  resendValidationEmailSuccess: () => ({
    type: RESEND_VALIDATION_EMAIL_SUCCESS
  }),

  resendValidationEmailFailure: (error) => ({
    type: RESEND_VALIDATION_EMAIL_FAILURE,
    payload: {
      error
    }
  }),

  forgotPwdRequest: (email) => ({
    type: FORGOT_PWD_REQUEST,
    payload: {
      email,
    }
  }),

  forgotPwdSuccess: () => ({
    type: FORGOT_PWD_SUCCESS
  }),

  forgotPwdFailure: (error) => ({
    type: FORGOT_PWD_FAILURE,
    payload: {
      error
    }
  }),

  changePwdRequest: (oldPassword, newPassword) => ({
    type: CHANGE_PWD_REQUEST,
    payload: {
      oldPassword,
      newPassword,
    }
  }),

  changePwdSuccess: () => ({
    type: CHANGE_PWD_SUCCESS,
  }),

  changePwdFailure: (error) => ({
    type: CHANGE_PWD_FAILURE,
    payload: {
      error,
    }
  }),

  updateToken: (accessToken, idToken) => ({
    type: UPDATE_TOKEN,
    payload: {
      accessToken,
      idToken,
    }
  }),

}