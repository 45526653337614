import Immutable from 'seamless-immutable'
import * as EventActionTypes from './actions'

/* ------------- Initial State ------------- */
const INITIAL_STATE = Immutable({
  fetching: false,
  create_fetching: false,
  delete_fetching: false,
  error: null,
  create_error: null,
  delete_error: null,
  event_list: [],
})

/* ------------- Reducer ------------- */
export function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    case EventActionTypes.FETCH_EVENTS_REQUEST:
      return state.merge({
        fetching: true,
        error: null,
      })

    case EventActionTypes.FETCH_EVENTS_SUCCESS:
      return state.merge({
        event_list: payload.events,
        fetching: false,
      })

    case EventActionTypes.FETCH_EVENTS_FAILURE:
      return state.merge({
        fetching: false,
        error: payload.error,
      })

    case EventActionTypes.ADD_EVENT_REQUEST:
      return state.merge({
        create_fetching: true,
        create_error: null,
      })

    case EventActionTypes.ADD_EVENT_SUCCESS:
      return state.merge({
        create_fetching: false,
      })

    case EventActionTypes.ADD_EVENT_FAILURE:
      return state.merge({
        create_fetching: false,
        create_error: payload.error,
      })

    case EventActionTypes.DELETE_EVENT_REQUEST:
      return state.merge({
        delete_fetching: true,
        delete_error: null,
      })

    case EventActionTypes.DELETE_EVENT_SUCCESS:
      return state.merge({
        delete_fetching: false,
      })

    case EventActionTypes.DELETE_EVENT_FAILURE:
      return state.merge({
        delete_fetching: false,
        delete_error: payload.error,
      })

    case EventActionTypes.CLEAR_EVENTS:
      return state.merge({
        event_list: [],
      })

    default:
      return state

  }
}