import { connect } from 'react-redux'
import ForgotPasswordScreenView from '../components/ForgotPasswordScreenView'
import AuthActions from '../../../modules/auth/actions'

export const mapStateToProps = ({ auth }) => {
  return {
    fetching: auth.forgot_pwd_fetching,
    error: auth.forgot_pwd_error,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email) => dispatch(AuthActions.forgotPwdRequest(email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreenView)