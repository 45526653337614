import { call, put, } from 'redux-saga/effects'
import api from '../../services/api'
import ActionCreators from './actions'
import OrgActionCreators from '../orgs/actions'

export function* sendInvite({ payload: { orgId, email, role }}) {
  try {
    const response = yield call(api.invites.sendInvite, orgId, email, role)
    if (response && response.status === 200) {

      yield put(ActionCreators.orgInviteSuccess(response.data))

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.orgInviteFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.orgInviteFailure(message))
  }
}

export function* resendInvite({ payload: { inviteId }}) {
  try {
    const response = yield call(api.invites.resendInvite, inviteId)
    if (response && response.status === 200) {

      yield put(ActionCreators.resendInviteSuccess(inviteId,response.data))

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.resendInviteFailure(inviteId,message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.resendInviteFailure(inviteId,message))
  }
}

export function* revokeInvite({ payload: { inviteId }}) {
  try {
    const response = yield call(api.invites.revokeInvite, inviteId)
    if (response && response.status === 200) {

      yield put(ActionCreators.revokeInviteSuccess(inviteId))

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.revokeInviteFailure(inviteId,message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.revokeInviteFailure(inviteId,message))
  }
}

export function* acceptInvite({ payload: { email, code }}) {
  try {
    const response = yield call(api.invites.acceptInvite, email, code)
    if (response && response.status === 200) {

      yield put(ActionCreators.acceptInviteSuccess(code,response.data))
      yield put(OrgActionCreators.selectOrg(response.data.id))

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.acceptInviteFailure(code,message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.acceptInviteFailure(code,message))
  }
}

export function* declineInvite({ payload: { email, code }}) {
  try {
    const response = yield call(api.invites.declineInvite, email, code)
    if (response && response.status === 200) {

      yield put(ActionCreators.declineInviteSuccess(code,response.data))

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.declineInviteFailure(code,message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.declineInviteFailure(code,message))
  }
}

