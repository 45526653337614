import { connect } from 'react-redux'
import LoginScreenView from '../components/LoginScreenView'
import AuthActions from '../../../modules/auth/actions'

export const mapStateToProps = ({ auth }) => {
  return {
    fetching: auth.fetching,
    error: auth.error,
    access_token: auth.access_token,
    existing_user: auth.existing_user,
    forgot_pwd_fetching: auth.forgot_pwd_fetching,
    forgot_pwd_error: auth.forgot_pwd_error,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch(AuthActions.authRequest(email,password)),
  forgotPassword: (email) => dispatch(AuthActions.forgotPwdRequest(email)),
  clearSignup: () => dispatch(AuthActions.clearSignup()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreenView)