import { connect } from 'react-redux'
import AcceptInviteScreenView from '../components/AcceptInviteScreenView'
import InviteActions from '../../../modules/invites/actions'
import OrgActions from '../../../modules/orgs/actions'

export const mapStateToProps = ({ auth, invites }) => {
  return {
    fetching: invites.accept_decline_fetching,
    error: invites.accept_decline_error,
    accessToken: auth.access_token,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  fetchOrgs: () => dispatch(OrgActions.fetchOrgsRequest()),
  acceptInvite: (email,code) => dispatch(InviteActions.acceptInviteRequest(email,code)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInviteScreenView)