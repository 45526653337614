/* examples of adding/removing persist keys for future use
export const migrations = {
  0: (state) => {
    console.log(state)
    return {
      auth: state.auth.merge({
        new_key: 'test'
      }),
      orgs: state.orgs,
    }
  },
  1: (state) => {
    return {
      auth: state.auth.without("new_key"),
      orgs: state.orgs,
    }
  }
}
*/

export const migrations = {

}