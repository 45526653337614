import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Styled from 'styled-components'
import { Form, Button, Spinner, Alert } from 'react-bootstrap'
import StackLogo from '../../../assets/images/Stack_Sports_logo_41.png'

class LoginScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      password: '',
      email: '',
      validated: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
    this.initSignup = this.initSignup.bind(this)
  }

  componentDidUpdate() {

    // redirect on successful login

    let { from } = this.props.location.state || { from: { pathname: "/" } }

    if (this.props.access_token) {
      if (this.props.existing_user)
        this.props.history.replace(from)
      else
        this.props.history.replace("/signup")
    }

  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({validated: true})

    const { email, password } = this.state
    if ((email !== '') && (password !== '')) {
      this.props.login(email,password)
    }

  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      this.setState({email: email})
    } else {
      this.setState({email: null})
    }
  }

  initSignup() {
    this.props.clearSignup()
    this.props.history.push("/signup")
  }

  render() {

    const { validated, email } = this.state
    const { fetching, error, history } = this.props
    const search = new URLSearchParams(this.props.location.search)
    const emailValidated = search.get("validated")

    // this code attempts to determine both the browser and os version
    // if ios < 14.3, user must use Safari due to apple limiting camera access
    var agent = window.navigator.userAgent
    let start = agent.indexOf( 'OS ' );
    let versionString = null
    let isIOS = false
    if( ( agent.indexOf( 'iPhone' ) > -1 || agent.indexOf( 'iPad' ) > -1 ) && start > -1 ){
        isIOS = true
        const end = agent.substr( start + 3 ).indexOf(" ")
        versionString = ( agent.substr( start + 3, end ).replace( '_', '.' ) )
    }
    const OsVersionNumber = parseFloat(versionString)
    const isSafari = !(navigator.userAgent.match("FxiOS") || navigator.userAgent.match("CriOS"))


    return(
      <MainContainer>
        <img style={{marginRight: '10px'}} src={StackLogo} alt="stack logo" />
        <div style={{fontSize: '20px', fontWeight: 'bold', paddingTop: '20px'}}>StackStream MVP Demo</div>
        <div>Internal Use Only - v{process.env.REACT_APP_VERSION}</div>

        { (isIOS && !isSafari && OsVersionNumber < 14.3)
          ? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px'}}>
              <div style={{fontWeight: 'bold'}}>Sorry, this browser is not supported</div>
              <div>Please update to iOS v14.3 or later, or use Safari browser.</div>
            </div>
          : <Fragment>

              <Form style={{paddingTop: '30px'}} noValidate validated={validated} onSubmit={this.handleSubmit}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Email"
                    onChange={(e) => this.validateEmail(e.target.value)}
                    style={{width: '200px'}}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your email address.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="password"
                    required
                    placeholder="Password"
                    onChange={(e) => this.setState({password: e.target.value})}
                    style={{width: '200px'}}
                  />
                  <div style={{display: 'flex', justifyContent: 'right'}}>
                    <Button variant="link" style={{padding: '0px'}} onClick={() => history.push('/password-reset')}>Forgot Password</Button>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    Please enter a password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Button variant="primary" type="submit" disabled={!email || fetching} style={{width: '100%'}}>
                    {fetching
                      ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Connecting...</Fragment>
                      : "Log In"
                    }
                  </Button>
                </Form.Group>
                <Button variant="secondary" style={{width: '100%'}} onClick={this.initSignup}>
                  Sign Up
                </Button>
              </Form>

              {error &&
                <Alert variant="danger" style={{marginTop: '36px'}}>
                  {error}
                </Alert>
              }

              { emailValidated &&
                <Alert variant="success" style={{marginTop: '36px'}}>
                  Email was successfully verified! Please log in.
                </Alert>
              }

          </Fragment>
        }

      </MainContainer>
    )
  }

}

const MainContainer = Styled.div`
  color: #FFF;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export default withRouter(LoginScreen)