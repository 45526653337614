import React, { Component, Fragment } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import ValidatePassword from '../../../services/utils/ValidatePassword'

class RegistrationForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      betaCode: '',
      validated: false,
      pwdValidated: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.validatePwd = this.validatePwd.bind(this)

  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const { password, confirmPassword, firstName, lastName, pwdValidated, betaCode } = this.state
    const { signupEmail, token, fetching, ssoStatus } = this.props

    if (!fetching) {

      this.setState({validated: true})

      if ( firstName !== '' && lastName !== '' && betaCode !== '') {
        if ((ssoStatus && token) || (!ssoStatus && pwdValidated && (password === confirmPassword))) {
          this.props.onSubmit(signupEmail,password,firstName,lastName,token,betaCode)
        }
      }

    }

  }

  validatePwd(pwd) {

    if (ValidatePassword(pwd)) {
      this.setState({password: pwd, pwdValidated: true})
    } else {
      this.setState({password: pwd, pwdValidated: false})
    }
  }

  render() {

    const { validated, password, confirmPassword, pwdValidated } = this.state
    const { fetching, signupEmail, ssoStatus, onReset } = this.props

    return(

      <Form style={{paddingTop: '30px'}} noValidate validated={validated} onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Control
              type="text"
              required
              value={signupEmail}
              disabled={true}
              style={{width: '200px'}}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              required
              placeholder="First Name"
              onChange={(e) => this.setState({firstName: e.target.value})}
              style={{width: '200px'}}
            />
            <Form.Control.Feedback type="invalid" style={{color: 'white'}}>
              Please enter your first name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              required
              placeholder="Last Name"
              onChange={(e) => this.setState({lastName: e.target.value})}
              style={{width: '200px'}}
            />
            <Form.Control.Feedback type="invalid" style={{color: 'white'}}>
              Please enter your last name.
            </Form.Control.Feedback>
          </Form.Group>
          { !ssoStatus &&
            <Fragment>
              <Form.Group>
                <Form.Control
                  type="password"
                  required
                  placeholder="Password"
                  onChange={(e) => this.validatePwd(e.target.value)}
                  style={{width: '200px'}}
                  isInvalid={validated && !pwdValidated}
                />
                <Form.Control.Feedback type="invalid" style={{color: 'white'}}>
                  Password must contain a minimum of 8<br />characters, 1 uppercase, 1 lowercase and<br />1 special character.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="password"
                  required
                  placeholder="Confirm Password"
                  onChange={(e) => this.setState({confirmPassword: e.target.value})}
                  style={{width: '200px'}}
                  isInvalid={validated && (password !== confirmPassword)}
                  isValid={validated && (password === confirmPassword)}
                />
                <Form.Control.Feedback type="invalid" style={{color: 'white'}}>
                  { password === '' ? 'Please confirm your password.' : 'Passwords must match.' }
                </Form.Control.Feedback>
              </Form.Group>
            </Fragment>
          }
          <Form.Group>
            <Form.Control
              type="text"
              required
              placeholder="Beta Test Access Code"
              onChange={(e) => this.setState({betaCode: e.target.value})}
              style={{width: '200px'}}
            />
            <Form.Control.Feedback type="invalid" style={{color: 'white'}}>
              Please enter access code for the<br />beta test program.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Button variant="primary" type="submit" style={{width: '100%'}}>
              {fetching
                ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Registering...</Fragment>
                : "Sign Up"
              }
            </Button>
          </Form.Group>
          <Button variant="secondary" onClick={onReset} style={{width: '100%'}}>Back</Button>
        </Form>

    )
  }

}

export default RegistrationForm