import React, { Component, Fragment } from 'react'
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap'

class EditOrgModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name: this.props.currentValue,
      validated: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onHide = this.onHide.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.props.working) {
      this.setState({validated: true})
      const { name } = this.state
      if (name !== '') {
        this.props.handleSubmit(name)
      }
    }

  }

  componentDidUpdate(prevProps) {

    // if everything went fine, dismiss
    if (prevProps.working && !this.props.working && !this.props.error) {
      this.setState({validated: false})
      this.props.onHide()
      this.props.onSuccess()
    }
  }

  onHide() {
    this.props.onHide()
    this.setState({name: this.props.currentValue, validated: false, })
  }

  render() {

    const { validated, name } = this.state
    const { working, error } = this.props

    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        centered
        keyboard={true}
      >
        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Edit Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Organization Name</Form.Label>
            <Form.Control required type="text" value={name} onChange={(e) => this.setState({name: e.target.value})} />
            <Form.Control.Feedback type="invalid">
              Please provide a valid organization name.
            </Form.Control.Feedback>
          </Form.Group>
          {
            error &&
            <Alert variant="danger">{error}</Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onHide}>Cancel</Button>
          <Button type="submit" variant="primary" disabled={working}>
            {working
              ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Saving...</Fragment>
              : "Update"
            }
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    )
  }

}

export default EditOrgModal