/* ------------- Action Types ----------- */
export const STARTUP = 'STARTUP'
export const STARTUP_FINISHED = 'STARTUP_FINISHED'
export const REHYDRATION_FINISHED = 'REHYDRATION_FINISHED'

/* ------------- Action Creators ------------- */
export default {
  startup: () => ({
    type: STARTUP,
  }),

  startupFinished: () => ({
    type: STARTUP_FINISHED,
  }),

  rehydrationFinished: () => ({
    type: REHYDRATION_FINISHED,
  }),

}
