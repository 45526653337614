import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Styled from 'styled-components'
import { Spinner, Button } from 'react-bootstrap'
import StackLogo from '../../../assets/images/Stack_Sports_logo_41.png'

class AcceptInviteScreen extends Component {

  componentDidMount() {

    const { fetching, acceptInvite } = this.props
    const search = new URLSearchParams(this.props.location.search)
    const email = search.get("email")
    const code = search.get("code")

    if (!fetching.includes(code)) {

      if (email && code) {
        acceptInvite(email,code)
      }
    }

  }

  componentDidUpdate(prevProps) {

    const search = new URLSearchParams(this.props.location.search)
    const code = search.get("code")

    // validation succeeded, go to home screen
    if (prevProps.fetching.includes(code) && !this.props.fetching.includes(code) && !this.props.error) {
      this.props.history.replace("/")
    }

  }

  render() {

    const { fetching, error } = this.props
    const search = new URLSearchParams(this.props.location.search)
    const code = search.get("code")

    return(
      <MainContainer>
        <img style={{marginRight: '10px'}} src={StackLogo} alt="stack logo" />
        <div style={{fontSize: '20px', fontWeight: 'bold', paddingTop: '20px'}}>StackStream MVP Demo</div>
        <div>Internal Use Only - v{process.env.REACT_APP_VERSION}</div>

        <div style={{marginTop: '20px'}}>


          { fetching.includes(code) &&
            <div style={{display: 'flex', flexDirection: 'column', fontSize: '20px', fontWeight: 'bold'}}>
              Verifying invite code...
              <Spinner animation="border" style={{color: '#FFF', alignSelf: 'center', marginTop: '10px'}} />
            </div>
          }

          { error &&
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div>{error}</div>
              <Button style={{marginTop: '20px'}} onClick={() => this.props.history.replace("/")}>Back to Events</Button>
            </div>
          }

        </div>


      </MainContainer>
    )
  }

}

const MainContainer = Styled.div`
  color: #FFF;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export default withRouter(AcceptInviteScreen)