import React, { Component, Fragment } from 'react'
import { Modal, Button, Form, Spinner, Alert, Col } from 'react-bootstrap'
import moment from 'moment'

class AddEventModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      location: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      validated: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onHide = this.onHide.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.props.working) {
      this.setState({validated: true})
      const { name, location, startDate, startTime, endDate, endTime } = this.state

      const convertedStartTime = startDate !== '' ? moment(startDate+" "+startTime, "YYYY-M-D HH:mm").utc().format() : ''
      const convertedEndTime = endDate !== '' ? moment(endDate+" "+endTime, "YYYY-M-D HH:mm").utc().format() : ''

      const validStart = (startDate === '' || (startDate !== '' && startTime !== '')) ? true : false
      const validEnd = (endDate === '' || (endDate !== '' && endTime !== '')) ? true : false

      if (name !== '' && validStart && validEnd) {
        this.props.handleSubmit({name,location,start: convertedStartTime,end: convertedEndTime})
      }
    }

  }

  componentDidUpdate(prevProps) {

    // if everything went fine, dismiss
    if (prevProps.working && !this.props.working && !this.props.error) {
      this.onHide()
      this.props.onSuccess()
    }
  }

  onHide() {
    this.props.onHide()
    this.setState({name: '', location: '', startDate: '', startTime: '', endDate: '', endTime: '', validated: false, })
  }

  render() {

    const { validated, startDate, startTime, endDate, endTime } = this.state
    const { working, error } = this.props

    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        centered
        keyboard={true}
      >
        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Event Name</Form.Label>
            <Form.Control required type="text" onChange={(e) => this.setState({name: e.target.value})} />
            <Form.Control.Feedback type="invalid">
              Please provide a valid event name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Location</Form.Label>
            <Form.Control placeholder="e.g. Lucas Oil Stadium, Field 4, etc" onChange={(e) => this.setState({location: e.target.value})} />
          </Form.Group>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" placeholder="YYYY-MM-DD" onChange={(e) => this.setState({startDate: e.target.value})} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Start Time</Form.Label>
                <Form.Control
                  type="time"
                  placeholder="HH:MM"
                  isInvalid={validated && (startDate !== '') && (startTime === '')}
                  onChange={(e) => this.setState({startTime: e.target.value})}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide start time.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" placeholder="YYYY-MM-DD" onChange={(e) => this.setState({endDate: e.target.value})} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>End Time</Form.Label>
                <Form.Control
                  type="time"
                  placeholder="HH:MM"
                  isInvalid={validated && (endDate !== '') && (endTime === '')}
                  onChange={(e) => this.setState({endTime: e.target.value})}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide end time.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
          { error &&
            <Alert variant="danger">{error}</Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onHide}>Cancel</Button>
          <Button type="submit" variant="primary" disabled={working}>
            {working
              ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Saving...</Fragment>
              : "Add Event"
            }
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    )
  }

}

export default AddEventModal