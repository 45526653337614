export const START_STREAM_REQUEST = 'START_STREAM_REQUEST'
export const START_STREAM_SUCCESS = 'START_STREAM_SUCCESS'
export const START_STREAM_FAILURE = 'START_STREAM_FAILURE'
export const STOP_STREAM_REQUEST = 'STOP_STREAM_REQUEST'
export const STOP_STREAM_SUCCESS = 'STOP_STREAM_SUCCESS'
export const STOP_STREAM_FAILURE = 'STOP_STREAM_FAILURE'

export default {

  startStreamRequest: (eventId,roomSid) => ({
    type: START_STREAM_REQUEST,
    payload: {
      eventId,
      roomSid,
    }
  }),

  startStreamSuccess: () => ({
    type: START_STREAM_SUCCESS,
  }),

  startStreamFailure: (error) => ({
    type: START_STREAM_FAILURE,
    payload: {
      error,
    },
  }),

  stopStreamRequest: (eventId) => ({
    type: STOP_STREAM_REQUEST,
    payload: {
      eventId,
    }
  }),

  stopStreamSuccess: () => ({
    type: STOP_STREAM_SUCCESS,
  }),

  stopStreamFailure: (error) => ({
    type: STOP_STREAM_FAILURE,
    payload: {
      error,
    },
  }),



}