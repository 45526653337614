import React, { Component, Fragment } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'

class SignupForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: null,
      validated: false,
    }

    this.validateEmail = this.validateEmail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      this.setState({email: email})
    } else {
      this.setState({email: null})
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const { email } = this.state
    this.props.onSubmit(email)

  }

  render() {

    const { email, validated } = this.state
    const { fetching } = this.props

    return(

      <Form style={{paddingTop: '30px'}} noValidate validated={validated} onSubmit={this.handleSubmit}>
        <Form.Group>
          <Form.Control
            type="text"
            required
            placeholder="Email Address"
            onChange={(e) => this.validateEmail(e.target.value)}
            style={{width: '200px'}}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your email address.
          </Form.Control.Feedback>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={(!email || fetching)} onClick={this.handleSubmit} style={{width: '100%'}}>
          {fetching
            ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Verifying...</Fragment>
            : "Next"
          }
        </Button>
      </Form>

    )
  }

}

export default SignupForm