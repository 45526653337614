import React, { Component, Fragment } from 'react'
import { Col, Row, ListGroup, Button, Spinner } from 'react-bootstrap'
import moment from 'moment'
import { XCircleFill, PencilSquare, EnvelopeFill } from 'react-bootstrap-icons'

class MemberListItem extends Component {

  render() {

    const {
      type,
      item,
      deleteMember,
      editMember,
      resendInvite,
      inviteResendFetching,
      revokeInvite,
      currentUser,
    } = this.props

    return (
      <ListGroup.Item>
        <Row>
          <Col style={{display: 'flex', alignItems: 'center'}}>
            { (type === "user")
              ? <Fragment>{item.last_name}, {item.first_name} ({item.email})</Fragment>
              : <Fragment>{item.email} ({item.status})</Fragment>
            }
          </Col>
          <Col style={{display: 'flex', alignItems: 'center'}}>
            <div style={{fontWeight: 'bold', marginRight: '5px'}}>Role:</div> {item.role}
          </Col>
          <Col style={{display: 'flex', alignItems: 'center'}}>
            { (type === "invite") &&
              <Fragment><div style={{fontWeight: 'bold', marginRight: '5px'}}>Sent:</div> {moment(item.sent).format('dddd MMMM Do YYYY, h:mma')}</Fragment>
            }
          </Col>
          <Col xs="auto">
            <Button variant="danger" onClick={type === "user" ? deleteMember : revokeInvite} style={{marginRight: '10px'}} disabled={currentUser === item.email ? true : false}><XCircleFill /></Button>
            { (type === "user")
              ? <Button variant="success" onClick={editMember} disabled={currentUser === item.email ? true : false}>
                  <PencilSquare />
                </Button>
              : <Button variant="secondary" onClick={resendInvite} disabled={inviteResendFetching}>
                  { inviteResendFetching
                    ? <Spinner as="span" animation="border" size="sm" />
                    : <EnvelopeFill />
                  }
                </Button>
            }
          </Col>
        </Row>
      </ListGroup.Item>
    )
  }

}

export default MemberListItem