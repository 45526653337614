export default (api) => ({

  auth: {

    login: (email, password) => {
      return api.post(`/auth/login`, {
        email,
        password
      })
    },
    tokenLogin: (idToken, accessToken, refreshToken) => {
      return api.post(`auth/token-login`, {
        id_token: idToken,
        access_token: accessToken,
        refresh_token: refreshToken,
      })
    },
    video: () => {
      return api.post(`/auth/video`,{})
    },
    signupCheck: (email) => {
      return api.get(`/auth/check-existing?email=${encodeURIComponent(email.toLowerCase())}`)
    },
    register: (email, password, firstName, lastName, token = null, betaCode) => {
      return api.post(`/auth/register`, {
        email: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        token: token,
        beta_code: betaCode,
      })
    },
    validateEmail: (email,validationCode) => {
      return api.post(`/auth/verify-email`, {
        email: email,
        validation_code: validationCode,
      })
    },
    resendValidationEmail: (email) => {
      return api.get(`auth/resend-validation-email?email=${encodeURIComponent(email.toLowerCase())}`)
    },
    forgotPassword: (email) => {
      return api.post(`/auth/forgot-password`, {
        email: email,
      })
    },
    changePassword: (oldPassword,newPassword) => {
      return api.post(`/auth/change-password`, {
        old_password: oldPassword,
        new_password: newPassword,
      })
    },
    refreshToken: (refreshToken,email) => {
      return api.post(`/auth/refresh-token`, {
        token: refreshToken,
        email: email,
      })
    },

  },

  cameras: {

    getAll: (eventId) => {
      return api.get(`/cameras/?event_id=${eventId}`)
    },
    createCamera: (camera) => {
      return api.post(`/cameras`,camera)
    },
    deleteCamera: (cameraId) => {
      return api.delete(`/cameras/${cameraId}`)
    },
    updateCamera: (cameraId,camera) => {
      return api.put(`/cameras/${cameraId}`,camera)
    },

  },

  events: {

    getAll: (orgId) => {
      return api.get(`/events?org=${orgId}`)
    },
    getEvent: (eventId) => {
      return api.get(`/events/${eventId}`)
    },
    createEvent: (event) => {
      return api.post(`/events`,event)
    },
    deleteEvent: (eventId) => {
      return api.delete(`/events/${eventId}`)
    },

  },

  invites: {

    sendInvite: (orgId,email,role) => {
      return api.post(`/invites`,{
        org_id: orgId,
        email: email,
        role: role,
      })
    },
    resendInvite: (inviteId) => {
      return api.put(`/invites/${inviteId}`)
    },
    revokeInvite: (inviteId) => {
      return api.delete(`/invites/${inviteId}`)
    },
    acceptInvite: (email,code) => {
      return api.post(`/invites/accept`,{
        email: email,
        invite_code: code,
      })
    },
    declineInvite: (email,code) => {
      return api.post(`/invites/decline`,{
        email: email,
        invite_code: code,
      })
    },

  },

  orgs: {
    getAll: () => {
      return api.get(`/orgs`)
    },
    getOrg: (orgId) => {
      return api.get(`/orgs/${orgId}`)
    },
    createOrg: (name) => {
      return api.post(`/orgs`,{name: name})
    },
    updateOrg: (orgId,name) => {
      return api.patch(`/orgs/${orgId}`,{name: name})
    },
    deleteOrg: (orgId) => {
      return api.delete(`/orgs/${orgId}`)
    },
    updateMember: (orgId,userId,role) => {
      return api.patch(`/orgs/${orgId}/members/${userId}`,{role: role})
    },
    deleteMember: (orgId,userId) => {
      return api.delete(`/orgs/${orgId}/members/${userId}`)
    },
  },

  streams: {

    start: (eventId,roomSid) => {
      return api.post(`/stream/${eventId}/startFull`,{room_sid: roomSid})
    },
    stop: (eventId) => {
      return api.post(`/stream/${eventId}/stopFull`)
    }

  },

})