import { put, } from 'redux-saga/effects'
import ActionCreators from './actions'
import { api as apiInstance } from '../../services/api'

// Process STARTUP action
export function* startup(store) {

  console.log("Startup in progress...")
  //yield delay(500)

  // put any startup actions here

  yield put(ActionCreators.startupFinished())
}

export function* rehydrate(action) {

  // check for rehydrated session
  // if it exists, set auth on API instance
  console.log("Rehydrating...")
  if (action.payload) {

    if (action.payload.auth && action.payload.auth.access_token){
      apiInstance.defaults.headers.common.Authorization = `Bearer ${action.payload.auth.access_token}`
    }

  }

  yield put(ActionCreators.rehydrationFinished())

}