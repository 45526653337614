import { call, put, } from 'redux-saga/effects'
import api from '../../services/api'
import ActionCreators from './actions'

export function* startStream({ payload: { eventId, roomSid }}) {
  try {
    const response = yield call(api.streams.start,eventId,roomSid)
    if (response && response.status === 200) {
      yield put(ActionCreators.startStreamSuccess(response.data))
    } else {
      const message = 'Oops! Something went wrong, please try again'
      yield put(ActionCreators.startStreamFailure(message))
    }
  } catch (e) {
    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }
    yield put(ActionCreators.startStreamFailure(message))
  }
}

export function* stopStream({ payload: { eventId }}) {
  try {
    const response = yield call(api.streams.stop,eventId)
    if (response && response.status === 200) {
      yield put(ActionCreators.stopStreamSuccess(response.data))
    } else {
      const message = 'Oops! Something went wrong, please try again'
      yield put(ActionCreators.stopStreamFailure(message))
    }
  } catch (e) {
    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }
    yield put(ActionCreators.stopStreamFailure(message))
  }
}

