import React, { Component, Fragment } from 'react'
import { Modal, Button, Form, Spinner, Alert, } from 'react-bootstrap'

class EditMemberModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      role: 'USER',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onHide = this.onHide.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.props.working) {
      const { role } = this.state
      this.props.handleSubmit(role)
    }

  }

  componentDidUpdate(prevProps) {

    if (!prevProps.orgMember && this.props.orgMember) {
      console.log(this.props.orgMember)
      this.setState({role: this.props.orgMember.role})
    }

    // if everything went fine, dismiss
    if (prevProps.working && !this.props.working && !this.props.error) {
      this.onHide()
      this.props.onSuccess()
    }
  }

  onHide() {
    this.props.onHide()
    this.setState({role: 'USER'})
  }

  render() {

    const { working, error, } = this.props

    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        centered
        keyboard={true}
      >
        <Form noValidate onSubmit={this.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Edit Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Role</Form.Label>
            <Form.Control as="select" value={this.state.role} onChange={(e) => this.setState({role: e.target.value})}>
              <option value="USER">User</option>
              <option value="ADMIN">Admin</option>
            </Form.Control>
          </Form.Group>
          {
            error &&
            <Alert variant="danger">{error}</Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onHide}>Cancel</Button>
          <Button type="submit" variant="primary" disabled={working}>
            {working
              ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Saving...</Fragment>
              : "Save"
            }
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    )
  }

}

export default EditMemberModal