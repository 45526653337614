import React, { Component, Fragment } from 'react'
import { Modal, Button, Form, Spinner, Alert, Col } from 'react-bootstrap'

class InviteModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      role: 'USER',
      validated: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onHide = this.onHide.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.props.working) {
      this.setState({validated: true})
      const { email, role } = this.state
      if (email !== '') {
        this.props.handleSubmit(email,role)
      }
    }

  }

  componentDidUpdate(prevProps) {

    // if everything went fine, dismiss
    if (prevProps.working && !this.props.working && !this.props.error) {
      this.onHide()
      this.props.onSuccess()
    }
  }

  onHide() {
    this.props.onHide()
    this.setState({email: '', role: 'USER', validated: false, })
  }

  render() {

    const { validated } = this.state
    const { working, error } = this.props

    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        centered
        keyboard={true}
      >
        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Invite New Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Recipient Email</Form.Label>
                <Form.Control required type="text" onChange={(e) => this.setState({email: e.target.value})} />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid recipient email.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control as="select" defaultValue="USER" onChange={(e) => this.setState({role: e.target.value})}>
                  <option value="USER">User</option>
                  <option value="ADMIN">Admin</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Form.Row>
          {
            error &&
            <Alert variant="danger">{error}</Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onHide}>Cancel</Button>
          <Button type="submit" variant="primary" disabled={working}>
            {working
              ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Sending...</Fragment>
              : "Send Invite"
            }
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    )
  }

}

export default InviteModal