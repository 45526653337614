import { connect } from 'react-redux'
import EventDetailScreenView from '../components/EventDetailScreenView'
import SelectedEventActions from '../../../modules/selected_event/actions'
import AuthActions from '../../../modules/auth/actions'
import CameraActions from '../../../modules/cameras/actions'
import StreamActions from '../../../modules/streams/actions'
import OrgActions from '../../../modules/orgs/actions'

export const mapStateToProps = ({ auth, selected_event, cameras, orgs }) => {
  return {
    fetching: selected_event.fetching,
    error: selected_event.error,
    event: selected_event.event,
    videoIdentity: auth.video_service_identity,
    videoToken: auth.video_token,
    cameraList: cameras.camera_list,
    localCameraId: cameras.local_camera_id,
    selectedOrg: orgs.selected_org,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  fetchEvent: (eventId) => dispatch(SelectedEventActions.fetchEventRequest(eventId)),
  deselectEvent: () => dispatch(SelectedEventActions.deselectEvent()),
  refreshVideoToken: () => dispatch(AuthActions.videoAuthRequest()),
  fetchCameras: (eventId) => dispatch(CameraActions.fetchCamerasRequest(eventId)),
  addCamera: (camera) => dispatch(CameraActions.addCameraRequest(camera)),
  updateCamera: (cameraId, camera) => dispatch(CameraActions.updateCameraRequest(cameraId,camera)),
  deleteCamera: (cameraId) => dispatch(CameraActions.deleteCameraRequest(cameraId)),
  startStream: (eventId,roomSid) => dispatch(StreamActions.startStreamRequest(eventId,roomSid)),
  stopStream: (eventId) => dispatch(StreamActions.stopStreamRequest(eventId)),
  fetchOrgs: () => dispatch(OrgActions.fetchOrgsRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailScreenView)