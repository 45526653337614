import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import Styled from 'styled-components'
import VideoPlayer from '../../components/VideoPlayer'
import PlayerPoster from '../../assets/images/video_player_poster.png'
import StackLogo from '../../assets/images/Stack_Sports_logo_41.png'
import axios from 'axios'

const CDN_BASE = process.env.REACT_APP_CDN_BASE

class PlaybackScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      videoReady: false,
      statusMessage: 'Waiting for stream to begin...'
    }

    this.checkStreamStatus = this.checkStreamStatus.bind(this)

  }

  componentDidMount() {

    this.checkStreamStatus()

  }

  checkStreamStatus() {

    console.log("Checking stream...")

    const eventId = this.props.match.params.id
    const streamURL = CDN_BASE+`${eventId}/index.m3u8`

    axios.get(streamURL)
    .then((response) => {
      this.setState({statusMessage: 'Stream is starting! Stand by...'})
      // add 10 sec delay to start video so seek control initializes
      setTimeout(() => this.setState({videoReady: true}),15000)
    })
    .catch((error) => {
      // check again in 10 seconds
      setTimeout(this.checkStreamStatus,10000)
    })

  }

  render() {

    const eventId = this.props.match.params.id
    const { videoReady, statusMessage } = this.state

    const videoJsOptions = {
      controls: true,
      fill: true,
      liveui: true,
      sources: [
        {
          src: CDN_BASE+`${eventId}/index.m3u8`,
          type: "application/x-mpegURL"
        }
      ],
      preload: 'auto',
      poster: PlayerPoster,

      html5: {
        nativeTextTracks: false,
        hls: {
          enableLowInitialPlaylist: true,
          smoothQualityChange: true,
          overrideNative: true,
          nativeTextTracks: false,
        },
      },
    }


    return(
      <MainContainer>

        <div style={{width: '100%', height: '100%', padding: '50px'}}>
          { videoReady
            ? <VideoPlayer { ...videoJsOptions } />
            : <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '1px solid white'}}>
                <img src={StackLogo} alt="logo" />
                <div style={{fontSize: '18px', margin: '20px 0 20px 0' }}>{statusMessage}</div>
                <div style={{width: '50px', height: '50px', margin: '20px'}}>
                  <Spinner animation="border" />
                </div>
              </div>
          }
        </div>

      </MainContainer>
    )
  }

}

const MainContainer = Styled.div`
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #FFF;
  background-color: #071743;

`

export default withRouter(PlaybackScreen)