import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Video, { LocalDataTrack } from 'twilio-video'
import Styled from 'styled-components'
import ParticipantVideo from '../../../components/ParticipantVideo'
import TitleHeader from '../../../components/TitleHeader'

class BroadcastViewScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      room: null,
      participants: [],
      cameraNameMap: {}
    }

    this.eventId = props.match.params.id

    this.refreshCameraList = this.refreshCameraList.bind(this)
    this.dataCallbackListener = this.dataCallbackListener.bind(this)

    // bind event listeners
    this.beforeunloadListener = this.beforeunloadListener.bind(this)
    this.participantConnectedListener = this.participantConnectedListener.bind(this)
    this.participantDisconnectedListener = this.participantDisconnectedListener.bind(this)
    this.trackSubscribedListener = this.trackSubscribedListener.bind(this)
  }

  beforeunloadListener() {
    const { room } = this.state
    if (room)
      room.disconnect()
  }
  // room listeners
  participantConnectedListener(participant) {
    let identity = JSON.parse(participant.identity)
    console.log("Joining: " + identity.user);
    this.setState({participants: this.state.room.participants})
  }
  participantDisconnectedListener(participant) {
    let identity = JSON.parse(participant.identity)
    console.log("Disconnected: " + identity.user);
    this.setState({participants: this.state.room.participants})
  }
  trackSubscribedListener(track, publication, participant) {
    if (publication.kind === "data") {
      publication.track.on('message',this.dataCallbackListener)
    }
  }

  componentDidMount() {

    const search = new URLSearchParams(this.props.location.search)
    const authKey = search.get("key")
    const authSecret = search.get("secret")

    this.props.login(authKey,authSecret)

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.accessToken !== this.props.accessToken) {
      // user is now logged in, do the init stuff

      // get event details
      this.props.fetchEvent(this.eventId)
      // we refresh the video token every time we jump into event details because
      // it has a relatively short idle timeout. refreshing here also kicks off
      // the process of joining the room and setting the room in component state.
      this.props.refreshVideoToken()
      // get details about cameras from other devices
      this.props.fetchCameras(this.eventId)
    }

    // once we've refreshed the token, join the room
    if (prevProps.videoToken !== this.props.videoToken) {
      this.joinVideoRoom()
    }

    // remap the camera list whenever we reload it from the API
    if ((prevProps.cameraList !== this.props.cameraList) || (prevState.room !== this.state.room))
      this.refreshCameraList()

  }

  joinVideoRoom() {

    console.log("Connecting to video service...")
    let connectOptions = {
      name: this.eventId,
      automaticSubscription: true,
      video: false,
      audio: false,
      tracks: [new LocalDataTrack()]
    }
    Video.connect(this.props.videoToken, connectOptions).then((room) => {
      console.log("Joined "+this.eventId+" as "+JSON.parse(this.props.videoIdentity).user)
      this.setState({room: room, participants: room.participants},() => {

        const { room } = this.state

        // set up listener to prevent orphaned connections on browser close/refresh
        window.addEventListener("beforeunload", this.beforeunloadListener)
        window.addEventListener("pagehide", this.beforeunloadListener)

        // console current room state
        room.participants.forEach(participant => {
          let identity = JSON.parse(participant.identity)
          console.log("Already in Room: " + identity.user);
        });

        // set up event listeners for room

        room.on('participantConnected', this.participantConnectedListener)
        room.on('participantDisconnected', this.participantDisconnectedListener)

        // subscribe to all data tracks
        room.on('trackSubscribed', this.trackSubscribedListener)

      })
    }, error => {
      alert('Could not connect to video service: ' + error.message);
    })

  }


  componentWillUnmount() {
    window.removeEventListener("beforeunload", () => this.state.room.disconnect())
  }

  refreshCameraList() {

    const { room, participants } = this.state

    if (room) {

      let cameraNameMap = {}

      this.props.cameraList.forEach(camera => {
        if (participants.has(camera.participant_sid) || (camera.participant_sid === room.localParticipant.sid))
          cameraNameMap[camera.participant_sid] = {id: camera.id, name: camera.name, type: camera.type, broadcast: camera.broadcast ? 1 : 0, muted: camera.muted ? 1 : 0}
      })
      this.setState({cameraNameMap: cameraNameMap})

    }
  }

  dataCallbackListener(message) {
    const dataObj = JSON.parse(message)

    switch(dataObj.action) {
      case 'updateCameraStatus':
        const { id, name, type, broadcast, muted, participant_id } = dataObj.data
        const updatedCamObject = {id, name, type, broadcast, muted}
        this.setState({cameraNameMap: {...this.state.cameraNameMap, [participant_id]: updatedCamObject}})
        break
      default:
    }
  }

  render() {

    const { participants, cameraNameMap } = this.state

    const participantsArray = []
    var scoreCamParticipant = null
    if (participants) {
      for (const participant of participants.values()) {
        if (cameraNameMap[participant.sid] && cameraNameMap[participant.sid].broadcast === 1) {
          if (cameraNameMap[participant.sid].type === "SCOREBOARD")
            scoreCamParticipant = participant
          else
            participantsArray.push(participant)
        }
      }
    }

    let numRows = 1
    let numCols = 1
    if (participantsArray.length > 1)
      numCols = 2
    if (participantsArray.length > 2)
      numRows = 2

    return(
      <Fragment>

        <TitleHeader
          selectedEvent={this.props.event}
        />

        <MainContainer>
          <div style={{flex: 1, display: 'grid', gridTemplateColumns: `repeat(${numCols},1fr)`, gridTemplateRows: `repeat(${numRows},1fr)`, width: '100%', height: '0px'}}>
            {participantsArray.map(participant => {
              return(
                <ParticipantVideo
                  key={participant.sid}
                  participant={participant}
                  title={cameraNameMap[participant.sid].name}
                />
              )
            })}
          </div>
          {scoreCamParticipant &&
            <div style={{position: 'absolute', display: 'flex', bottom: '0px', width: '500px', height: '280px'}}>
              <ParticipantVideo
                participant={scoreCamParticipant}
                title={cameraNameMap[scoreCamParticipant.sid].name}
              />
            </div>
          }
        </MainContainer>
      </Fragment>
    )
  }

}

const MainContainer = Styled.div`
  align-items: center;
  justify-content: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #FFF;
  background-color: #071743;

`

export default withRouter(BroadcastViewScreen)