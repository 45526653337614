import { takeLatest, all } from 'redux-saga/effects'
import { REHYDRATE } from 'redux-persist'

import * as StartupActionTypes from './startup/actions'
import * as StartupSagas from './startup/sagas'

import * as AuthActionTypes from './auth/actions'
import * as AuthSagas from './auth/sagas'

import * as CameraActionTypes from './cameras/actions'
import * as CameraSagas from './cameras/sagas'

import * as EventActionTypes from './events/actions'
import * as EventSagas from './events/sagas'

import * as InviteActionTypes from './invites/actions'
import * as InviteSagas from './invites/sagas'

import * as OrgActionTypes from './orgs/actions'
import * as OrgSagas from './orgs/sagas'

import * as SelectedEventActionTypes from './selected_event/actions'
import * as SelectedEventSagas from './selected_event/sagas'

import * as StreamActionTypes from './streams/actions'
import * as StreamSagas from './streams/sagas'


const root = function* root(store) {
  yield all([
    takeLatest(REHYDRATE, StartupSagas.rehydrate),
    takeLatest(StartupActionTypes.STARTUP, StartupSagas.startup, store),
    takeLatest(AuthActionTypes.AUTH_REQUEST, AuthSagas.auth),
    takeLatest(AuthActionTypes.TOKEN_AUTH_REQUEST, AuthSagas.tokenAuth),
    takeLatest(AuthActionTypes.VIDEO_AUTH_REQUEST, AuthSagas.videoAuth),
    takeLatest(AuthActionTypes.SIGNUP_CHECK_REQUEST, AuthSagas.signupCheck),
    takeLatest(AuthActionTypes.REGISTER_REQUEST, AuthSagas.register),
    takeLatest(AuthActionTypes.VALIDATE_EMAIL_REQUEST, AuthSagas.validateEmail),
    takeLatest(AuthActionTypes.RESEND_VALIDATION_EMAIL_REQUEST, AuthSagas.resendValidationEmail),
    takeLatest(AuthActionTypes.FORGOT_PWD_REQUEST, AuthSagas.forgotPassword),
    takeLatest(AuthActionTypes.CHANGE_PWD_REQUEST, AuthSagas.changePassword),
    takeLatest(CameraActionTypes.FETCH_CAMERAS_REQUEST, CameraSagas.fetchCameras),
    takeLatest(CameraActionTypes.ADD_CAMERA_REQUEST, CameraSagas.addCamera),
    takeLatest(CameraActionTypes.DELETE_CAMERA_REQUEST, CameraSagas.deleteCamera),
    takeLatest(CameraActionTypes.UPDATE_CAMERA_REQUEST, CameraSagas.updateCamera),
    takeLatest(EventActionTypes.FETCH_EVENTS_REQUEST, EventSagas.fetchEvents),
    takeLatest(EventActionTypes.ADD_EVENT_REQUEST, EventSagas.addEvent),
    takeLatest(EventActionTypes.DELETE_EVENT_REQUEST, EventSagas.deleteEvent),
    takeLatest(InviteActionTypes.ORG_INVITE_REQUEST, InviteSagas.sendInvite),
    takeLatest(InviteActionTypes.RESEND_INVITE_REQUEST, InviteSagas.resendInvite),
    takeLatest(InviteActionTypes.REVOKE_INVITE_REQUEST, InviteSagas.revokeInvite),
    takeLatest(InviteActionTypes.ACCEPT_INVITE_REQUEST, InviteSagas.acceptInvite),
    takeLatest(InviteActionTypes.DECLINE_INVITE_REQUEST, InviteSagas.declineInvite),
    takeLatest(OrgActionTypes.FETCH_ORGS_REQUEST, OrgSagas.fetchOrgs),
    takeLatest(OrgActionTypes.ORG_DETAILS_REQUEST, OrgSagas.fetchOrgDetails),
    takeLatest(OrgActionTypes.ADD_ORG_REQUEST, OrgSagas.addOrg),
    takeLatest(OrgActionTypes.UPDATE_ORG_REQUEST, OrgSagas.updateOrg),
    takeLatest(OrgActionTypes.DELETE_ORG_REQUEST, OrgSagas.deleteOrg),
    takeLatest(OrgActionTypes.UPDATE_ORG_MEMBER_REQUEST, OrgSagas.updateOrgMember),
    takeLatest(OrgActionTypes.DELETE_ORG_MEMBER_REQUEST, OrgSagas.deleteOrgMember),
    takeLatest(SelectedEventActionTypes.FETCH_EVENT_REQUEST, SelectedEventSagas.fetchEvent),
    takeLatest(StreamActionTypes.START_STREAM_REQUEST, StreamSagas.startStream),
    takeLatest(StreamActionTypes.STOP_STREAM_REQUEST, StreamSagas.stopStream)
  ])
}

/**
 * Inject a saga.
 * @param {Object} store: Global store of the app.
 * @param {Array}  sagas: List of saga functions to inject.
 */
export function injectSagas(store, sagas) {
  // TODO: Add check to validate that sagas is valid (is an array of generators)
  sagas.map(store.runSaga, store)
}

export default root
