export const FETCH_CAMERAS_REQUEST = 'FETCH_CAMERAS_REQUEST'
export const FETCH_CAMERAS_SUCCESS = 'FETCH_CAMERAS_SUCCESS'
export const FETCH_CAMERAS_FAILURE = 'FETCH_CAMERAS_FAILURE'
export const ADD_CAMERA_REQUEST = 'ADD_CAMERA_REQUEST'
export const ADD_CAMERA_SUCCESS = 'ADD_CAMERA_SUCCESS'
export const ADD_CAMERA_FAILURE = 'ADD_CAMERA_FAILURE'
export const DELETE_CAMERA_REQUEST = 'DELETE_CAMERA_REQUEST'
export const DELETE_CAMERA_SUCCESS = 'DELETE_CAMERA_SUCCESS'
export const DELETE_CAMERA_FAILURE = 'DELETE_CAMERA_FAILURE'
export const UPDATE_CAMERA_REQUEST = 'UPDATE_CAMERA_REQUEST'
export const UPDATE_CAMERA_SUCCESS = 'UPDATE_CAMERA_SUCCESS'
export const UPDATE_CAMERA_FAILURE = 'UPDATE_CAMERA_FAILURE'


export default {

  fetchCamerasRequest: (eventId) => ({
    type: FETCH_CAMERAS_REQUEST,
    payload: {
      eventId
    },
  }),

  fetchCamerasSuccess: (cameras) => ({
    type: FETCH_CAMERAS_SUCCESS,
    payload: {
      cameras,
    },
  }),

  fetchCamerasFailure: (error) => ({
    type: FETCH_CAMERAS_FAILURE,
    payload: {
      error,
    },
  }),

  addCameraRequest: (camera) => ({
    type: ADD_CAMERA_REQUEST,
    payload: {
      camera,
    }
  }),

  addCameraSuccess: (camera) => ({
    type: ADD_CAMERA_SUCCESS,
    payload: {
      camera,
    }
  }),

  addCameraFailure: (error) => ({
    type: ADD_CAMERA_FAILURE,
    payload: {
      error,
    },
  }),

  deleteCameraRequest: (cameraId) => ({
    type: DELETE_CAMERA_REQUEST,
    payload: {
      cameraId,
    }
  }),

  deleteCameraSuccess: (cameraId) => ({
    type: DELETE_CAMERA_SUCCESS,
    payload: {
      cameraId,
    }
  }),

  deleteCameraFailure: (error) => ({
    type: DELETE_CAMERA_FAILURE,
    payload: {
      error,
    },
  }),

  updateCameraRequest: (cameraId,camera) => ({
    type: UPDATE_CAMERA_REQUEST,
    payload: {
      cameraId,
      camera,
    }
  }),

  updateCameraSuccess: (camera) => ({
    type: UPDATE_CAMERA_SUCCESS,
    payload: {
      camera,
    }
  }),

  updateCameraFailure: (error) => ({
    type: UPDATE_CAMERA_FAILURE,
    payload: {
      error,
    },
  }),

}