import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Card, Modal, Form, ListGroup, Spinner } from 'react-bootstrap'
import moment from 'moment'
import Video, { LocalDataTrack } from 'twilio-video'
import Styled from 'styled-components'
import CameraListItem from '../../../components/CameraListItem'

const CAMERA_CONSTRAINTS = {
  width: 4096,
  height: 2160
}

class EventDetailScreen extends Component {

  constructor(props) {
    super(props)
    this.videoPreviewContainerRef = React.createRef()

    const streamStatus = "STOPPED"
    this.state = {
      room: null,
      participants: [],
      streamStatus: streamStatus,
      streamWorking: (streamStatus === "STARTING" || streamStatus === "STOPPING") ? true : false,
      localCameraAcitvated: false,
      addCameraModalOpen: false,
      previewTrack: null,
      deviceList: [],
      selectedDevice: null,
      validated: false,
      cameraName: '',
      cameraRole: 'STANDARD',
      cameraNameMap: {}
    }

    this.eventId = props.match.params.id

    this.pollTimer = null

    this.joinVideoRoom = this.joinVideoRoom.bind(this)
    this.addCameraInit = this.addCameraInit.bind(this)
    this.changePreviewDevice = this.changePreviewDevice.bind(this)
    this.addCameraCancel = this.addCameraCancel.bind(this)
    this.addCameraConfirm = this.addCameraConfirm.bind(this)
    this.stopCamera = this.stopCamera.bind(this)
    this.updateCameraStatus = this.updateCameraStatus.bind(this)
    this.returnToEventList = this.returnToEventList.bind(this)
    this.refreshCameraList = this.refreshCameraList.bind(this)
    this.emitSocketAction = this.emitSocketAction.bind(this)
    this.dataTrackListener = this.dataTrackListener.bind(this)
    this.startStream = this.startStream.bind(this)
    this.stopStream = this.stopStream.bind(this)
    this.pollTimerStart = this.pollTimerStart.bind(this)
    this.pollStream = this.pollStream.bind(this)

    // bind event listeners
    this.beforeunloadListener = this.beforeunloadListener.bind(this)
    this.participantConnectedListener = this.participantConnectedListener.bind(this)
    this.participantDisconnectedListener = this.participantDisconnectedListener.bind(this)
    this.trackSubscribedListener = this.trackSubscribedListener.bind(this)
    this.trackPublishedListener = this.trackPublishedListener.bind(this)

  }

  beforeunloadListener() {
    if (this.state.localCameraActivated) {
      this.props.deleteCamera(this.props.localCameraId)
    }
    const { room } = this.state
    if (room)
      room.disconnect()

  }

  // room listeners
  participantConnectedListener(participant) {
    let identity = JSON.parse(participant.identity)
    console.log("Joining: " + identity.user);
    this.setState({participants: this.state.room.participants})
  }
  participantDisconnectedListener(participant) {
    let identity = JSON.parse(participant.identity)
    console.log("Disconnected: " + identity.user);
    this.setState({participants: this.state.room.participants})
  }
  trackSubscribedListener(track, publication, participant) {
    if (publication.kind === "data") {
      publication.track.on('message',this.dataTrackListener)
    }
  }

  // participant listeners
  trackPublishedListener(publication) {
    if (publication.kind === "video")
      this.setState({localCameraActivated: true})
  }

  componentDidMount() {

    if (!this.props.selectedOrg)
      this.props.fetchOrgs()

    const eventId = this.props.match.params.id

    // get event details
    this.props.fetchEvent(eventId)

    // we refresh the video token every time we jump into event details because
    // it has a relatively short idle timeout. refreshing here also kicks off
    // the process of joining the room and setting the room in component state.
    this.props.refreshVideoToken()

    // get details about cameras from other devices
    this.props.fetchCameras(eventId)

  }

  componentDidUpdate(prevProps,prevState) {

    // if we switch orgs, go back to event list
    if (prevProps.selectedOrg !== this.props.selectedOrg)
      this.props.history.replace("/")

    // once we've refreshed the token, join the room
    if (prevProps.videoToken !== this.props.videoToken) {
      this.joinVideoRoom()
    }

    // remap the camera list whenever we reload it from the API
    if ((prevProps.cameraList !== this.props.cameraList) || (prevState.room !== this.state.room))
      this.refreshCameraList()

    // catch initial event load and stream status changes
    if (prevProps.event !== this.props.event) {
      const streamStatus = this.props.event.stream_status
      const newStreamWorking = (streamStatus === "STARTING" || streamStatus === "STOPPING") ? true : false

      if (prevState.streamWorking === false && newStreamWorking === true && !this.pollTimer) {
        this.pollTimerStart()
      }

      this.setState({streamStatus: streamStatus, streamWorking: newStreamWorking})
    }

  }

  componentWillUnmount() {

    const { room } = this.state

    if (room) {
      console.log("Disconnecting...")
      room.disconnect()

      // remove event listeners for room

      room.removeListener('participantConnected', this.participantConnectedListener);
      room.removeListener('participantDisconnected', this.participantDisconnectedListener)

      // subscribe to all data tracks
      room.removeListener('trackSubscribed', this.trackSubscribedListener)

      if (room.localParticipant) {
        // pick up async publishing of local tracks
        room.localParticipant.removeListener('trackPublished', this.trackPublishedListener)
      }

    }

    // remove listener to prevent orphaned connections on browser close/refresh
    window.removeEventListener("beforeunload", this.beforeunloadListener)
    window.removeEventListener('pagehide', this.beforeunloadListener)


    // remove publication subscriptions
    try {
      room.participants.forEach(participant => {
        participant.tracks.forEach(publication => {
          if (publication.kind === "data" && publication.track) {
            publication.track.removeListener('message',this.dataTrackListener)
          }
        })
      })
    } catch (error) {
      console.log(error)
    }

    // kill timers
    clearTimeout(this.pollTimer)

  }

  joinVideoRoom() {

    console.log("Connecting to video service...")
    let connectOptions = {
      name: this.eventId,
      automaticSubscription: true,
      video: false,
      audio: false,
      tracks: [new LocalDataTrack()]
    }
    Video.connect(this.props.videoToken, connectOptions).then((room) => {
      console.log("Joined "+this.eventId+" as "+JSON.parse(this.props.videoIdentity).user)
      this.setState({room: room, participants: room.participants},() => {

        const { room } = this.state
        const participant = this.state.room.localParticipant

        // set up listener to prevent orphaned connections on browser close/refresh
        window.addEventListener("beforeunload", this.beforeunloadListener)
        window.addEventListener("pagehide", this.beforeunloadListener)

        // pick up async publishing of local tracks
        participant.on('trackPublished', this.trackPublishedListener)

        // console current room state
        room.participants.forEach(participant => {
          let identity = JSON.parse(participant.identity)
          console.log("Already in Room: " + identity.user);
        });

        // set up event listeners for room

        room.on('participantConnected', this.participantConnectedListener)
        room.on('participantDisconnected', this.participantDisconnectedListener)

        // subscribe to all data tracks
        room.on('trackSubscribed', this.trackSubscribedListener)

      })
    }, error => {
      alert('Could not connect to video service: ' + error.message);
    })


  }

  addCameraInit() {

    // ~~ this is a hack for safari
    // we need to call getUserMedia BEFORE enumerateDevices because the list will
    // be empty if permission hasn't yet been granted
    // ~~ bonus hack for chrome :(
    // even though this call just inits the camera to enumerate devices, we STILL
    // have to pass size constraints because chrome caches them for all subsequent
    // calls
    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {width: CAMERA_CONSTRAINTS.width, height: CAMERA_CONSTRAINTS.height},
    }).then(mediaStream => {

      // clean up the tracks here for the safari hack since we'll be creating them
      // from specific device ids below
      const mediaTracks = mediaStream.getTracks()
      mediaTracks.forEach(track => {
        track.stop()
      })

      navigator.mediaDevices.enumerateDevices().then(devices => {
        let videoDevices = []
        devices.forEach(device => {
          if (device.kind === "videoinput") {
            videoDevices.push({id: device.deviceId, label: device.label})
          }
        })
        this.setState({addCameraModalOpen: true, deviceList: videoDevices, selectedDevice: videoDevices[0].id},() => {
          const mediaContainer = this.videoPreviewContainerRef.current
          Video.createLocalVideoTrack({
            deviceId: { exact: videoDevices[0].id },
            width: CAMERA_CONSTRAINTS.width,
            height: CAMERA_CONSTRAINTS.height
          }).then(track => {
            mediaContainer.appendChild(track.attach())
            this.setState({previewTrack: track})
          })
        })
      })

    })

  }

  changePreviewDevice(deviceId) {

    this.state.previewTrack.stop()

    const mediaContainer = this.videoPreviewContainerRef.current
    Video.createLocalVideoTrack({
      deviceId: { exact: deviceId },
      width: CAMERA_CONSTRAINTS.width,
      height: CAMERA_CONSTRAINTS.height
    }).then(track => {
      mediaContainer.appendChild(track.attach())
      this.setState({previewTrack: track, selectedDevice: deviceId})
    })

  }

  addCameraCancel() {
    this.state.previewTrack.stop()
    this.setState({addCameraModalOpen: false, previewTrack: null, validated: false})
  }

  addCameraConfirm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({validated: true})

    const { cameraName, cameraRole, previewTrack, selectedDevice, room } = this.state

    if (cameraName !== '') {

      previewTrack.stop()
      this.setState({addCameraModalOpen: false, previewTrack: null, validated: false})

      let constraints = {deviceId: { exact: selectedDevice }}

      // check for ios devices
      // there is some issue with camera constraints here that needs further
      // investigation. it seems that there's a limit on twilio capture resolution.
      let isIOS = false
      if (['iPad','iPhone'].includes(navigator.platform))
        isIOS = true
      if (navigator.maxTouchPoints && (navigator.maxTouchPoints > 2))
        isIOS = true

      if (isIOS) {
        constraints.width = 1280
        constraints.height = 720
      } else {
        constraints.width = CAMERA_CONSTRAINTS.width
        constraints.height = CAMERA_CONSTRAINTS.height
      }

      // create and publish tracks to video service
      Video.createLocalVideoTrack(constraints).then(localVideoTrack => {
        try {
          //console.log(constraints)
          //console.log(localVideoTrack)
          room.localParticipant.publishTrack(localVideoTrack,{priority: 'high'});
        } catch (e) {
          console.log(e)
        }
      })
      Video.createLocalAudioTrack().then(localAudioTrack => {
        room.localParticipant.publishTrack(localAudioTrack);
      })

      // write camera to db
      const postObj = {name: cameraName, type: cameraRole, participant_sid: room.localParticipant.sid, event_id: room.name, broadcast: 0, muted: 0}
      this.props.addCamera(postObj)
      /*
      const postConfig = {
        headers: { Authorization: `Bearer ${this.props.accessToken}` }
      }
      axios.post(API_BASE+'cameras',postObj,postConfig).then(result => {
        const newCamMapObj = {id: result.data.id, name: cameraName, type: cameraRole, broadcast: 0, muted: 0}
        this.setState({cameraId: result.data.id, cameraNameMap: {...this.state.cameraNameMap, [room.localParticipant.sid]: newCamMapObj}})
      })
      */

    }

  }

  stopCamera() {

    const { room } = this.state

    room.localParticipant.videoTracks.forEach(publication => {
      publication.track.stop();
      publication.unpublish();
    });
    room.localParticipant.audioTracks.forEach(publication => {
      publication.track.stop();
      publication.unpublish();
    });
    this.setState({localCameraActivated: false})

    // emit socket action
    const camObj = {
      id: this.props.localCameraId,
      name: null,
      type: null,
      broadcast: 0,
      muted: 0,
      participant_id: this.state.room.localParticipant.sid
    }
    const actionObj = {action: 'updateCameraStatus', source: this.state.room.localParticipant.sid, data: camObj}
    this.emitSocketAction(actionObj)

    // remove camera from db
    this.props.deleteCamera(this.props.localCameraId)

  }

  updateCameraStatus(participantId,newStatus) {

    const oldCamObject = this.state.cameraNameMap[participantId]

    const updatedCamObject = {
      name: oldCamObject.name,
      type: oldCamObject.type,
      participant_sid: participantId,
      event_id: this.state.room.name,
      broadcast: newStatus.hasOwnProperty('broadcast') ? newStatus.broadcast : oldCamObject.broadcast,
      muted: newStatus.hasOwnProperty('muted') ? newStatus.muted : oldCamObject.muted
    }
    const nameMapObj = {
      id: oldCamObject.id,
      name: oldCamObject.name,
      type: oldCamObject.type,
      broadcast: newStatus.hasOwnProperty('broadcast') ? newStatus.broadcast : oldCamObject.broadcast,
      muted: newStatus.hasOwnProperty('muted') ? newStatus.muted : oldCamObject.muted
    }

    this.setState({cameraNameMap: {...this.state.cameraNameMap, [participantId]: nameMapObj}})

    // emit socket action
    const actionObj = {action: 'updateCameraStatus', source: this.state.room.localParticipant.sid, data: {...nameMapObj,participant_id: participantId}}
    this.emitSocketAction(actionObj)

    // update db
    this.props.updateCamera(oldCamObject.id,updatedCamObject)

    // if muting, disable local track publication
    if (oldCamObject.muted !== updatedCamObject.muted) {
      const tracks = this.state.room.localParticipant.tracks
      tracks.forEach(publication => {
        if (publication.kind && publication.kind === "audio") {
          if (updatedCamObject.muted === 1) {
            publication.track.disable()
          } else {
            publication.track.enable()
          }
        }
      })
    }

  }

  returnToEventList() {
    // clean up camera on exit
    // we don't call stopCamera() here because this component is getting unmounted
    if (this.state.localCameraActivated) {
      this.stopCamera()
      //this.props.deleteCamera(this.props.localCameraId)
    }

    this.props.deselectEvent()

    this.props.history.push('/')
  }

  refreshCameraList() {

    const { room, participants } = this.state

    if (room && participants) {
      let cameraNameMap = {}
      let orphanedCameras = []
      this.props.cameraList.forEach(camera => {
        if (!participants.has(camera.participant_sid) && (camera.participant_sid !== room.localParticipant.sid))
          orphanedCameras.push(camera.id)
        else
          cameraNameMap[camera.participant_sid] = {id: camera.id, name: camera.name, type: camera.type, broadcast: camera.broadcast ? 1 : 0, muted: camera.muted ? 1 : 0}
      })
      this.setState({cameraNameMap: cameraNameMap})

      // clean up orphan cameras in db
      orphanedCameras.forEach(camera => {
        this.props.deleteCamera(camera)
      })
    }

  }

  emitSocketAction(actionObj) {
    const { room } = this.state
    room.localParticipant.tracks.forEach(publication => {
      if (publication.kind === "data")
        publication.track.send(JSON.stringify({...actionObj,source: room.localParticipant.sid}))
    })
  }

  dataTrackListener(message) {

    const dataObj = JSON.parse(message)
    const { room } = this.state

    switch(dataObj.action) {
      case 'updateCameraStatus':
        const { id, name, type, broadcast, muted, participant_id } = dataObj.data
        const oldCamObject = this.state.cameraNameMap[participant_id]
        const updatedCamObject = {id, name, type, broadcast, muted}
        this.setState({cameraNameMap: {...this.state.cameraNameMap, [participant_id]: updatedCamObject}})
        // check if local track actions need to be taken (for mute)
        if ((room.localParticipant.sid === participant_id) && (oldCamObject.muted !== updatedCamObject.muted)) {
          const tracks = room.localParticipant.tracks
          tracks.forEach(publication => {
            if (publication.kind && publication.kind === "audio") {
              if (updatedCamObject.muted === 1) {
                publication.track.disable()
              } else {
                publication.track.enable()
              }
            }
          })
        }
        break
      case 'updateStreamingStatus':
        const { streamStatus } = dataObj.data
        const newStreamWorking = (streamStatus === "STOPPED" || streamStatus === "RUNNING") ? false : true
        this.setState({streamStatus: streamStatus, streamWorking: newStreamWorking})
        if (newStreamWorking) {
          this.pollTimerStart()
        }
        break
      default:
    }

  }

  startStream() {

    const { event } = this.props
    const { room } = this.state

    this.setState({streamWorking: true, streamStatus: "STARTING"},() => {

      this.props.startStream(event.id,room.sid)

      const actionObj = {action: 'updateStreamingStatus', source: this.state.room.localParticipant.sid, data: {streamStatus: "STARTING"}}
      this.emitSocketAction(actionObj)

      this.pollTimerStart()

    })

  }

  stopStream() {

    const { event } = this.props

    this.setState({streamWorking: true, streamStatus: "STOPPING"},() => {

      this.props.stopStream(event.id)

      const actionObj = {action: 'updateStreamingStatus', source: this.state.room.localParticipant.sid, data: {streamStatus: "STOPPING"}}
      this.emitSocketAction(actionObj)

      this.pollTimerStart()

    })

  }

  pollTimerStart() {
    this.pollTimer = setTimeout(() => {
      this.pollStream()
    },10000)
  }

  pollStream() {

    const { event } = this.props
    const { streamWorking } = this.state

    if (streamWorking) {
      this.props.fetchEvent(event.id)
      this.pollTimerStart()
    } else {
      clearTimeout(this.pollTimer)
    }



  }

  render() {

    const eventId = this.props.match.params.id
    const { event } = this.props
    const { room, localCameraActivated, cameraNameMap, participants, streamStatus, streamWorking } = this.state

    const { name, location, start_time, end_time } = {...event}

    let formattedTime = null
    if (start_time)
      formattedTime = moment(start_time).format('dddd MMMM Do YYYY, h:mma')
    if (end_time)
      formattedTime += "-"+moment(end_time).format('h:mma')

    const participantsArray = []
    for (const participant of participants.values()) {
      participantsArray.push(participant)
    }

    let streamButtonVal = ""
    switch(streamStatus) {
      case 'STOPPED':
        streamButtonVal = "Start Public Live Stream"
        break
      case 'STOPPING':
        streamButtonVal = "Stream Is Stopping..."
        break
      case 'RUNNING':
        streamButtonVal = "Stop Public Live Stream"
        break
      case 'STARTING':
        streamButtonVal = "Stream Is Starting..."
        break
      default:
    }

    const path = window.location.protocol + '//' + window.location.host


    return (

      <Fragment>

        <ContentContainer>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div><h2 style={{color: '#FFF', marginBottom: '20px'}}>Event Details</h2></div>
          <div><Button onClick={this.returnToEventList}>Back to Events</Button></div>
        </div>

        { event
          ? <Fragment>
            <Card style={{width: '100%', marginBottom: '5px'}}>

              <Card.Body>
                <Card.Title>{name}</Card.Title>
                <div style={{margin: '0 0 20px 0'}}>
                  {(formattedTime !== null) && <div><span style={{fontWeight: 'bold'}}>When:</span> {formattedTime}</div>}
                  {(location !== '') && <div><span style={{fontWeight: 'bold'}}>Where:</span> {location}</div>}
                  <div><span style={{fontWeight: 'bold'}}>Cameras:</span> {Object.keys(cameraNameMap).length}</div>
                  <div><span style={{fontWeight: 'bold'}}>Stream Status:</span> {streamStatus}</div>
                </div>
                <Button
                  variant={(streamStatus === "RUNNING" || streamStatus === "STOPPING") ? "danger" : "success"}
                  onClick={streamStatus === "RUNNING" ? this.stopStream : this.startStream}
                  disabled={streamWorking}>
                  {streamWorking
                    ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />{streamButtonVal}</Fragment>
                    : streamButtonVal
                  }
                </Button>
                { (streamStatus === "STARTING") &&
                  <div style={{marginTop: '10px'}}>It may take a minute or 2 for your stream to be created. Hang tight!</div>
                }
                { (streamStatus === "RUNNING") &&
                  <div style={{marginTop: '10px'}}>Your stream is ready! View it here: <a href={`${path}/view/${event.id}`} target="_blank" rel="noopener noreferrer">{`${path}/view/${event.id}`}</a></div>
                }
              </Card.Body>
            </Card>

            <div style={{display: 'flex', justifyContent: 'space-between', margin: '10px 0 10px 0'}}>
              <div><h2 style={{color: '#FFF'}}>Cameras</h2></div>
              <div><Button disabled={!room} onClick={localCameraActivated ? this.stopCamera : this.addCameraInit}>{localCameraActivated ? 'Stop Camera' : 'Add Camera'}</Button></div>
            </div>
            <div style={{flex: 1, overflowY: 'auto'}}>
              <ListGroup>
                {localCameraActivated &&
                  <CameraListItem
                    key={room.localParticipant.sid}
                    participant={room.localParticipant}
                    cameraName={cameraNameMap[room.localParticipant.sid] ? cameraNameMap[room.localParticipant.sid].name : null}
                    cameraRole={cameraNameMap[room.localParticipant.sid] ? cameraNameMap[room.localParticipant.sid].type : null}
                    broadcast={cameraNameMap[room.localParticipant.sid] ? cameraNameMap[room.localParticipant.sid].broadcast : 0}
                    muted={cameraNameMap[room.localParticipant.sid] ? cameraNameMap[room.localParticipant.sid].muted : 0}
                    refreshCameraList={() => this.props.fetchCameras(eventId)}
                    updateCameraStatus={this.updateCameraStatus}
                    thisDevice={true}
                  />
                }
                {
                  participantsArray.map(participant => {
                    return(
                      <CameraListItem
                        key={participant.sid}
                        participant={participant}
                        cameraName={cameraNameMap[participant.sid] ? cameraNameMap[participant.sid].name : "Loading..."}
                        cameraRole={cameraNameMap[participant.sid] ? cameraNameMap[participant.sid].type : null}
                        broadcast={cameraNameMap[participant.sid] ? cameraNameMap[participant.sid].broadcast : 0}
                        muted={cameraNameMap[participant.sid] ? cameraNameMap[participant.sid].muted : 0}
                        refreshCameraList={() => this.props.fetchCameras(eventId)}
                        updateCameraStatus={this.updateCameraStatus}
                      />
                    )
                  })
                }
              </ListGroup>
            </div>
            </Fragment>
          : <Spinner animation="border" style={{color: '#FFF', alignSelf: 'center', marginTop: '50px'}} />

        }

        <Modal
          show={this.state.addCameraModalOpen}
          onHide={() => this.addCameraCancel()}
          centered
          keyboard={true}
        >
          <Form noValidate validated={this.state.validated} onSubmit={this.addCameraConfirm}>
          <Modal.Header>
            <Modal.Title>Add Camera</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{display: 'flex', width: '100%', height: '250px'}}>
              <StyledMediaContainer ref={this.videoPreviewContainerRef} />
            </div>
            <Form.Row>

                <Form.Group style={{marginTop: '10px', flex: 1}}>
                  <Form.Label>Input Device</Form.Label>
                  <Form.Control as="select" onChange={(e) => this.changePreviewDevice(e.target.value)}>
                    {
                      this.state.deviceList.map(device => {
                        return(<option key={device.id} value={device.id}>{device.label}</option>)
                      })
                    }
                  </Form.Control>
                </Form.Group>

                <Form.Group style={{marginTop: '10px', flex: 1}}>
                  <Form.Label>Camera Role</Form.Label>
                  <Form.Control as="select" onChange={(e) => this.setState({cameraRole: e.target.value})}>
                    <option value="STANDARD">Standard</option>
                    <option value="SCOREBOARD">Scoreboard</option>
                  </Form.Control>
                </Form.Group>

            </Form.Row>
            <Form.Group>
              <Form.Label>Camera Name</Form.Label>
              <Form.Control required type="text" placeholder="SE Corner, Midfield, etc." onChange={(e) => this.setState({cameraName: e.target.value})} />
              <Form.Control.Feedback type="invalid">
                Please provide a valid camera name.
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.addCameraCancel()}>Cancel</Button>
            <Button variant="success" type="submit">Add Camera</Button>
          </Modal.Footer>
          </Form>
        </Modal>

      </ContentContainer>

      </Fragment>
    )
  }

}

const ContentContainer = Styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  background-color: #071743;
  overflow: hidden;
`

const StyledMediaContainer = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-height: inherit;
  max-width: inherit;
  min-width: inherit;
  min-height: inherit;
  overflow: hidden;
  position: relative;

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
  }
`;

export default withRouter(EventDetailScreen)
