import { connect } from 'react-redux'
import AuthRedirectScreenView from '../components/AuthRedirectScreenView'
import AuthActions from '../../../modules/auth/actions'

export const mapStateToProps = ({ auth }) => {
  return {
    fetching: auth.fetching,
    error: auth.error,
    accessToken: auth.access_token,
    existingUser: auth.existing_user,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  tokenAuth: (email,idToken,accessToken,refreshToken) => dispatch(AuthActions.tokenAuthRequest(email,idToken,accessToken,refreshToken))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthRedirectScreenView)