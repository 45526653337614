import Immutable from 'seamless-immutable'
import * as StreamActionTypes from './actions'

/* ------------- Initial State ------------- */
const INITIAL_STATE = Immutable({
  start_fetching: false,
  start_error: null,
  stop_fetching: false,
  stop_error: null,
})

/* ------------- Reducer ------------- */
export function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    case StreamActionTypes.START_STREAM_REQUEST:
      return state.merge({
        start_fetching: true,
        start_error: null,
      })

    case StreamActionTypes.START_STREAM_SUCCESS:
      return state.merge({
        start_fetching: false,
      })

    case StreamActionTypes.START_STREAM_FAILURE:
      return state.merge({
        start_fetching: false,
        start_error: payload.error,
      })

    case StreamActionTypes.STOP_STREAM_REQUEST:
      return state.merge({
        stop_fetching: true,
        stop_error: null,
      })

    case StreamActionTypes.STOP_STREAM_SUCCESS:
      return state.merge({
        stop_fetching: false,
      })

    case StreamActionTypes.STOP_STREAM_FAILURE:
      return state.merge({
        stop_fetching: false,
        stop_error: payload.error,
      })

    default:
      return state

  }
}