import React, { Component } from 'react'
import { ListGroup, Form } from 'react-bootstrap'
import ParticipantVideo from '../ParticipantVideo'

class CameraListItem extends Component {

  constructor(props) {
    super(props)
    this.mediaContainerRef = React.createRef()
    this.state = {
      videoTrackPresent: false
    }

    this.subscribedListener = this.subscribedListener.bind(this)
    this.unsubscribedListener = this.unsubscribedListener.bind(this)
    this.trackPublishedListener = this.trackPublishedListener.bind(this)
    this.trackUnpublishedListener = this.trackUnpublishedListener.bind(this)
  }

  // track listeners
  subscribedListener(track) {
    this.setState({videoTrackPresent: true})
  }
  unsubscribedListener(track) {
    this.setState({videoTrackPresent: false})
    this.props.refreshCameraList()
  }

  // participant listeners
  trackPublishedListener(publication) {
    if (publication.kind === "video") {
      this.setState({videoTrackPresent: true})
      this.props.refreshCameraList()
    }
  }
  trackUnpublishedListener(publication) {
    if (publication.kind === "video") {
      this.setState({videoTrackPresent: false})
      this.props.refreshCameraList()
    }
  }


  componentDidMount() {

    const { participant } = this.props
    const trackPublications = participant.tracks

    // set up listeners for existing tracks
    trackPublications.forEach(publication => {

      if (publication.kind === "video") {

        if (publication.isSubscribed === false) {
          publication.on('subscribed', this.subscribedListener)
        } else {
          this.setState({videoTrackPresent: true})
        }

        publication.on('unsubscribed', this.unsubscribedListener)

      }

    })

    participant.on('trackPublished', this.trackPublishedListener)
    participant.on('trackUnpublished', this.trackUnpublishedListener)

  }

  componentWillUnmount() {

    const { participant } = this.props
    const trackPublications = participant.tracks

    // tear down listeners for existing tracks
    trackPublications.forEach(publication => {

      if (publication.kind === "video") {

        if (publication.isSubscribed === false) {
          publication.removeListener('subscribed', this.subscribedListener)
        }

        publication.removeListener('unsubscribed', this.unsubscribedListener)

      }

    })

    participant.removeListener('trackPublished', this.trackPublishedListener)
    participant.removeListener('trackUnpublished', this.trackUnpublishedListener)

  }

  render() {

    const { cameraName, cameraRole, broadcast, muted, participant } = this.props
    const { videoTrackPresent } = this.state

    if (!videoTrackPresent)
      return null

    return(
      <ListGroup.Item>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div style={{display: 'flex', width: '200px', height: '112px', marginRight: '20px'}}>
            <ParticipantVideo hideTitle={true} participant={participant} />
          </div>
          <div>
            <div style={{fontWeight: 'bold', fontSize: '1rem'}}>{cameraName ? cameraName : 'Camera'}</div>
            {this.props.thisDevice &&
              <div style={{fontWeight: 'bold', fontSize: '0.75rem'}}>(this device)</div>
            }
            {cameraRole &&
              <div style={{fontWeight: 'bold', fontSize: '0.75rem'}}>Role: {cameraRole}</div>
            }
            <Form.Check
              type="checkbox"
              label="Add To Broadcast"
              checked={broadcast === 1 ? true : false}
              onChange={() => this.props.updateCameraStatus(participant.sid,{broadcast: broadcast === 1 ? 0 : 1})}
              style={{marginTop: '10px'}}
            />
            <Form.Check
              type="checkbox"
              label="Mute Audio"
              checked={muted === 1 ? true : false}
              onChange={() => this.props.updateCameraStatus(participant.sid,{muted: muted === 1 ? 0 : 1})}
              style={{marginTop: '10px'}}
            />
          </div>
        </div>
      </ListGroup.Item>
    )
  }
}

export default CameraListItem