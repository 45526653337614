import { call, put, } from 'redux-saga/effects'
import api, { api as apiInstance } from '../../services/api'
import ActionCreators from './actions'
import OrgActionCreators from '../orgs/actions'

export function* auth({ payload: { email, password } }) {
  try {

    const response = yield call(api.auth.login, email, password )
    if (response && response.status === 200) {
      const data = response.data.data
      const meta = response.data.meta

      yield put(ActionCreators.authSuccess(meta.existingUser, meta.emailValidated, data.AuthenticationResult.AccessToken, data.AuthenticationResult.RefreshToken, data.AuthenticationResult.IdToken))

      // set token header on API instance for subsequent calls
      if (data.AuthenticationResult.AccessToken) {
        apiInstance.defaults.headers.common.Authorization = `Bearer ${data.AuthenticationResult.AccessToken}`
      }

      // re-fetch orgs
      yield put(OrgActionCreators.fetchOrgsRequest(null))

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.authFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.authFailure(message))
  }
}

export function* tokenAuth({ payload: { idToken, accessToken, refreshToken } }) {
  try {

    const response = yield call(api.auth.tokenLogin, idToken, accessToken, refreshToken )
    if (response && response.status === 200) {
      const data = response.data.data
      const meta = response.data.meta

      yield put(ActionCreators.tokenAuthSuccess(meta.existingUser, meta.emailValidated, data.AuthenticationResult.AccessToken, data.AuthenticationResult.RefreshToken, data.AuthenticationResult.IdToken))

      // set token header on API instance for subsequent calls
      if (data.AuthenticationResult.AccessToken) {
        apiInstance.defaults.headers.common.Authorization = `Bearer ${data.AuthenticationResult.AccessToken}`
      }

      // re-fetch orgs
      yield put(OrgActionCreators.fetchOrgsRequest(null))

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.tokenAuthFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.tokenAuthFailure(message))
  }
}

export function* videoAuth() {
  try {

    const response = yield call(api.auth.video)
    if (response && response.status === 200) {
      const data = response.data

      yield put(ActionCreators.videoAuthSuccess(data.video_service_identity, data.video_token))

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.videoAuthFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.videoAuthFailure(message))
  }
}

export function* signupCheck({ payload: { email }}) {
  try {

    const response = yield call(api.auth.signupCheck,email)
    if (response && response.status === 200) {
      const data = response.data
      if (data.length > 0)
        yield put(ActionCreators.signupCheckSuccess(true))
      else
        yield put(ActionCreators.signupCheckSuccess(false))

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.signupCheckFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.signupCheckFailure(message))
  }
}

export function* register({ payload: { email, password, firstName, lastName, token, betaCode } }) {
  try {

    const response = yield call(api.auth.register, email, password, firstName, lastName, token, betaCode )
    if (response && response.status === 200) {
      const data = response.data.data
      const meta = response.data.meta

      // if there is no data block returned, user already auth'd prior to registration
      // reducer will use existing auth data in state
      if (data)
        yield put(ActionCreators.registerSuccess(meta.existingUser, meta.emailValidated, data.AuthenticationResult.AccessToken, data.AuthenticationResult.RefreshToken, data.AuthenticationResult.IdToken))
      else
        yield put(ActionCreators.registerSuccess(meta.existingUser, meta.emailValidated, null, null, null))

      // set token header on API instance for subsequent calls
      if (data && data.AuthenticationResult.AccessToken) {
        apiInstance.defaults.headers.common.Authorization = `Bearer ${data.AuthenticationResult.AccessToken}`
      }

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.registerFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.registerFailure(message))
  }
}

export function* validateEmail({ payload: { email, validationCode }}) {
  try {

    const response = yield call(api.auth.validateEmail,email,validationCode)
    if (response && response.status === 200) {
      const data = response.data

      yield put(ActionCreators.validateEmailSuccess(data.email_validated))

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.validateEmailFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.validateEmailFailure(message))
  }
}

export function* resendValidationEmail({ payload: { email }}) {
  try {

    const response = yield call(api.auth.resendValidationEmail,email)
    if (response && response.status === 200) {

      yield put(ActionCreators.resendValidationEmailSuccess())

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.resendValidationEmailFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.resendValidationEmailFailure(message))
  }
}

export function* forgotPassword({ payload: { email }}) {
  try {

    const response = yield call(api.auth.forgotPassword,email)
    if (response && response.status === 200) {

      yield put(ActionCreators.forgotPwdSuccess())

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.forgotPwdFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.forgotPwdFailure(message))
  }
}

export function* changePassword({ payload: { oldPassword, newPassword }}) {
  try {

    const response = yield call(api.auth.changePassword,oldPassword,newPassword)
    if (response && response.status === 200) {

      yield put(ActionCreators.changePwdSuccess())

    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.changePwdFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.changePwdFailure(message))
  }
}

