import Immutable from 'seamless-immutable'
import * as CameraActionTypes from './actions'

/* ------------- Initial State ------------- */
const INITIAL_STATE = Immutable({
  fetching: false,
  create_fetching: false,
  delete_fetching: false,
  update_fetching: false,
  error: null,
  create_error: null,
  delete_error: null,
  update_error: null,
  local_camera_id: null,
  camera_list: [],
})

/* ------------- Reducer ------------- */
export function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    case CameraActionTypes.FETCH_CAMERAS_REQUEST:
      return state.merge({
        fetching: true,
        error: null,
      })

    case CameraActionTypes.FETCH_CAMERAS_SUCCESS:
      return state.merge({
        camera_list: payload.cameras,
        fetching: false,
      })

    case CameraActionTypes.FETCH_CAMERAS_FAILURE:
      return state.merge({
        fetching: false,
        error: payload.error,
      })

    case CameraActionTypes.ADD_CAMERA_REQUEST:
      return state.merge({
        create_fetching: true,
        create_error: null,
      })

    case CameraActionTypes.ADD_CAMERA_SUCCESS:
      return state.merge({
        create_fetching: false,
        local_camera_id: payload.camera.id,
        camera_list: [
          ...state.camera_list,
          payload.camera,
        ]
      })

    case CameraActionTypes.ADD_CAMERA_FAILURE:
      return state.merge({
        create_fetching: false,
        create_error: payload.error,
      })

    case CameraActionTypes.DELETE_CAMERA_REQUEST:
      return state.merge({
        delete_fetching: true,
        delete_error: null,
      })

    case CameraActionTypes.DELETE_CAMERA_SUCCESS:
      return state.merge({
        delete_fetching: false,
        local_camera_id: (state.local_camera_id === payload.cameraId) ? null : state.local_camera_id,
        camera_list: state.camera_list.filter(camera => camera.id !== payload.cameraId),
      })

    case CameraActionTypes.DELETE_CAMERA_FAILURE:
      return state.merge({
        delete_fetching: false,
        delete_error: payload.error,
      })

    case CameraActionTypes.UPDATE_CAMERA_REQUEST:
      return state.merge({
        update_fetching: true,
        update_error: null,
      })

    case CameraActionTypes.UPDATE_CAMERA_SUCCESS:
      return state.merge({
        update_fetching: false,
      })

    case CameraActionTypes.UPDATE_CAMERA_FAILURE:
      return state.merge({
        update_fetching: false,
        update_error: payload.error,
      })

    default:
      return state

  }
}