import { connect } from 'react-redux'
import VerifyEmailScreenView from '../components/VerifyEmailScreenView'
import AuthActions from '../../../modules/auth/actions'

export const mapStateToProps = ({ auth }) => {
  return {
    fetching: auth.validate_fetching,
    error: auth.validate_error,
    validated: auth.email_validated,
    accessToken: auth.access_token,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  validateEmail: (email,validationCode) => dispatch(AuthActions.validateEmailRequest(email,validationCode))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailScreenView)