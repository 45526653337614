import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Div100vh from 'react-div-100vh'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import './App.css'

import AuthActions from './modules/auth/actions'
import OrgActions from './modules/orgs/actions'
import InviteActions from './modules/invites/actions'

import LoginScreen from './screens/LoginScreen'
import AuthRedirectScreen from './screens/AuthRedirectScreen'
import SignupScreen from './screens/SignupScreen'
import PlaybackScreen from './screens/PlaybackScreen'
import EventListScreen from './screens/EventListScreen'
import EventDetailScreen from './screens/EventDetailScreen'
import BroadcastScreen from './screens/BroadcastScreen'
import VerifyEmailScreen from './screens/VerifyEmailScreen'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen'
import OrgManagementScreen from './screens/OrgManagementScreen'
import AcceptInviteScreen from './screens/AcceptInviteScreen'

import VerifyEmailModal from './components/VerifyEmailModal'
import ChangePasswordModal from './components/ChangePasswordModal'
import AddOrgModal from './components/AddOrgModal'
import TitleHeader from './components/TitleHeader'

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      changePasswordVisible: false,
      addOrgVisible: false,
    }
  }

  componentDidMount() {
    //this.props.fetchOrgs(this.props.orgs.selected_org)
  }

  render() {

    const {
      auth,
      orgs,
      invites,
      logout,
      validateEmail,
      resendValidationEmail,
      changePassword,
      addOrg,
      selectOrg,
      acceptInvite,
      declineInvite,
      location
    } = this.props
    const { changePasswordVisible, addOrgVisible } = this.state

    const titleHeaderProps = {
      logout: logout,
      changePwd: () => this.setState({changePasswordVisible: true}),
      addOrg: () => this.setState({addOrgVisible: true}),
      manageOrg: () => this.props.history.push(`/org/${this.props.orgs.selected_org}`),
      orgs: orgs,
      invites: invites,
      selectOrg,
      acceptInvite,
      declineInvite,
    }

    return (
      <Div100vh className="App">

        <Switch>
          <Route path="/login">
            <LoginScreen />
          </Route>
          <Route path="/signup">
            <SignupScreen />
          </Route>
          <Route path="/view/:id">
            <PlaybackScreen />
          </Route>
          <Route path="/broadcast/:id">
            <BroadcastScreen />
          </Route>
          <Route path="/verify-email">
            <VerifyEmailScreen />
          </Route>
          <Route path="/password-reset">
            <ForgotPasswordScreen />
          </Route>
          <Route path="/auth-redirect">
            <AuthRedirectScreen />
          </Route>
          <ProtectedRoute
            auth={auth}
            path="/accept-invite"
          >
            <AcceptInviteScreen />
          </ProtectedRoute>
          <ProtectedRoute
            auth={auth}
            headerProps={titleHeaderProps}
            path="/org/:id"
          >
            <OrgManagementScreen />
          </ProtectedRoute>
          <ProtectedRoute
            auth={auth}
            headerProps={titleHeaderProps}
            path="/event/:id"
          >
            <EventDetailScreen />
          </ProtectedRoute>
          <ProtectedRoute
            auth={auth}
            headerProps={titleHeaderProps}
            path="/"
          >
            <EventListScreen
              addOrg={() => this.setState({addOrgVisible: true})}
            />
          </ProtectedRoute>
        </Switch>

        <VerifyEmailModal
          show={auth.access_token && auth.existing_user && !auth.email_validated && location.pathname !== "/verify-email"}
          onHide={() => {}}
          onLogout={logout}
          working={auth.validate_fetching}
          handleSubmit={validateEmail}
          error={auth.validate_error}
          email={auth.email}
          handleResend={resendValidationEmail}
          resendWorking={auth.resend_validation_fetching}
          resendError={auth.resend_validation_error}
        />

        <ChangePasswordModal
          show={changePasswordVisible}
          onHide={() => this.setState({changePasswordVisible: false})}
          handleSubmit={changePassword}
          working={auth.change_pwd_fetching}
          error={auth.change_pwd_error}
        />

        <AddOrgModal
          show={addOrgVisible}
          onHide={() => this.setState({addOrgVisible: false})}
          handleSubmit={addOrg}
          working={orgs.create_fetching}
          error={orgs.create_error}
          onSuccess={() => {
            this.props.fetchOrgs()
          }}
        />

      </Div100vh>
    )
  }

}

function ProtectedRoute({ auth, children, headerProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (auth.access_token && auth.existing_user) ? (
          <Fragment>
            { headerProps &&
              <TitleHeader
                handleLogout={headerProps.logout}
                handlePwdChange={headerProps.changePwd}
                handleManageOrg={headerProps.manageOrg}
                handleAddOrg={headerProps.addOrg}
                orgList={headerProps.orgs.org_list}
                inviteList={headerProps.orgs.invite_list}
                selectedOrg={headerProps.orgs.selected_org}
                handleSelectOrg={headerProps.selectOrg}
                handleAcceptInvite={headerProps.acceptInvite}
                handleDeclineInvite={headerProps.declineInvite}
                acceptDeclineFetching={headerProps.invites.accept_decline_fetching}
                email={auth.email}
              />
            }
            {children}
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    orgs: state.orgs,
    invites: state.invites,
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.logout()),
  validateEmail: (email,validationCode) => dispatch(AuthActions.validateEmailRequest(email,validationCode)),
  resendValidationEmail: (email) => dispatch(AuthActions.resendValidationEmailRequest(email)),
  changePassword: (oldPassword,newPassword) => dispatch(AuthActions.changePwdRequest(oldPassword,newPassword)),
  fetchOrgs: () => dispatch(OrgActions.fetchOrgsRequest()),
  selectOrg: (orgId) => dispatch(OrgActions.selectOrg(orgId)),
  addOrg: (name) => dispatch(OrgActions.addOrgRequest(name)),
  acceptInvite: (email,code) => dispatch(InviteActions.acceptInviteRequest(email,code)),
  declineInvite: (email,code) => dispatch(InviteActions.declineInviteRequest(email,code)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(App))