import { combineReducers } from 'redux'
import { reducer as startupReducer } from './startup/reducer'
import { reducer as authReducer } from './auth/reducer'
import { reducer as camerasReducer } from './cameras/reducer'
import { reducer as eventsReducer } from './events/reducer'
import { reducer as invitesReducer } from './invites/reducer'
import { reducer as orgsReducer } from './orgs/reducer'
import { reducer as selectedEventReducer } from './selected_event/reducer'
import { reducer as streamsReducer } from './streams/reducer'

export const makeRootReducer = (asyncReducers) => {
  const appReducer = combineReducers({
    /* TODO: combine module reducers here */
    app: startupReducer,
    auth: authReducer,
    cameras: camerasReducer,
    events: eventsReducer,
    invites: invitesReducer,
    orgs: orgsReducer,
    selected_event: selectedEventReducer,
    streams: streamsReducer,
    ...asyncReducers,
  })

  return (state, action) => {
    return appReducer(state, action)
  }
}

/**
 * Inject a reducer in the store.
 * @param {Object} store  : Global store of the app.
 * @param {String} key    : Key that will have the reducer injected into the store.
 * @param {Object} reducer: Specefic reducer to inject in the store.
 */
export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
