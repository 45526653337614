export const FETCH_ORGS_REQUEST = 'FETCH_ORGS_REQUEST'
export const FETCH_ORGS_SUCCESS = 'FETCH_ORGS_SUCCESS'
export const FETCH_ORGS_FAILURE = 'FETCH_ORGS_FAILURE'
export const ORG_DETAILS_REQUEST = 'ORG_DETAILS_REQUEST'
export const ORG_DETAILS_SUCCESS = 'ORG_DETAILS_SUCCESS'
export const ORG_DETAILS_FAILURE = 'ORG_DETAILS_FAILURE'
export const ADD_ORG_REQUEST = 'ADD_ORG_REQUEST'
export const ADD_ORG_SUCCESS = 'ADD_ORG_SUCCESS'
export const ADD_ORG_FAILURE = 'ADD_ORG_FAILURE'
export const UPDATE_ORG_REQUEST = 'UPDATE_ORG_REQUEST'
export const UPDATE_ORG_SUCCESS = 'UPDATE_ORG_SUCCESS'
export const UPDATE_ORG_FAILURE = 'UPDATE_ORG_FAILURE'
export const DELETE_ORG_REQUEST = 'DELETE_ORG_REQUEST'
export const DELETE_ORG_SUCCESS = 'DELETE_ORG_SUCCESS'
export const DELETE_ORG_FAILURE = 'DELETE_ORG_FAILURE'

export const UPDATE_ORG_MEMBER_REQUEST = 'UPDATE_ORG_MEMBER_REQUEST'
export const UPDATE_ORG_MEMBER_SUCCESS = 'UPDATE_ORG_MEMBER_SUCCESS'
export const UPDATE_ORG_MEMBER_FAILURE = 'UPDATE_ORG_MEMBER_FAILURE'
export const DELETE_ORG_MEMBER_REQUEST = 'DELETE_ORG_MEMBER_REQUEST'
export const DELETE_ORG_MEMBER_SUCCESS = 'DELETE_ORG_MEMBER_SUCCESS'
export const DELETE_ORG_MEMBER_FAILURE = 'DELETE_ORG_MEMBER_FAILURE'

export const SELECT_ORG = 'SELECT_ORG'
export const CLEAR_ORGS = 'CLEAR_ORGS'

export default {

  fetchOrgsRequest: (selectedOrg) => ({
    type: FETCH_ORGS_REQUEST,
    payload: {
      selectedOrg,
    },
  }),

  fetchOrgsSuccess: (data) => ({
    type: FETCH_ORGS_SUCCESS,
    payload: {
      data,
    },
  }),

  fetchOrgsFailure: (error) => ({
    type: FETCH_ORGS_FAILURE,
    payload: {
      error,
    },
  }),

  orgDetailsRequest: (orgId) => ({
    type: ORG_DETAILS_REQUEST,
    payload: {
      orgId,
    },
  }),

  orgDetailsSuccess: (org) => ({
    type: ORG_DETAILS_SUCCESS,
    payload: {
      org,
    },
  }),

  orgDetailsFailure: (error) => ({
    type: ORG_DETAILS_FAILURE,
    payload: {
      error,
    },
  }),

  addOrgRequest: (name) => ({
    type: ADD_ORG_REQUEST,
    payload: {
      name,
    }
  }),

  addOrgSuccess: (org) => ({
    type: ADD_ORG_SUCCESS,
    payload: {
      org,
    }
  }),

  addOrgFailure: (error) => ({
    type: ADD_ORG_FAILURE,
    payload: {
      error,
    },
  }),

  updateOrgRequest: (orgId, name) => ({
    type: UPDATE_ORG_REQUEST,
    payload: {
      orgId,
      name,
    },
  }),

  updateOrgSuccess: (org) => ({
    type: UPDATE_ORG_SUCCESS,
    payload: {
      org,
    },
  }),

  updateOrgFailure: (error) => ({
    type: UPDATE_ORG_FAILURE,
    payload: {
      error,
    },
  }),

  deleteOrgRequest: (orgId) => ({
    type: DELETE_ORG_REQUEST,
    payload: {
      orgId,
    }
  }),

  deleteOrgSuccess: () => ({
    type: DELETE_ORG_SUCCESS,
  }),

  deleteOrgFailure: (error) => ({
    type: DELETE_ORG_FAILURE,
    payload: {
      error,
    },
  }),

  updateOrgMemberRequest: (orgId,userId,role) => ({
    type: UPDATE_ORG_MEMBER_REQUEST,
    payload: {
      orgId,
      userId,
      role
    },
  }),

  updateOrgMemberSuccess: (orgMember) => ({
    type: UPDATE_ORG_MEMBER_SUCCESS,
    payload: {
      orgMember,
    },
  }),

  updateOrgMemberFailure: (error) => ({
    type: UPDATE_ORG_MEMBER_FAILURE,
    payload: {
      error
    }
  }),

  deleteOrgMemberRequest: (orgId,userId) => ({
    type: DELETE_ORG_MEMBER_REQUEST,
    payload: {
      orgId,
      userId,
    },
  }),

  deleteOrgMemberSuccess: (userId) => ({
    type: DELETE_ORG_MEMBER_SUCCESS,
    payload: {
      userId,
    },
  }),

  deleteOrgMemberFailure: (error) => ({
    type: DELETE_ORG_MEMBER_FAILURE,
    payload: {
      error,
    },
  }),

  selectOrg: (orgId) => ({
    type: SELECT_ORG,
    payload: {
      orgId,
    },
  }),

  clearOrgs: () => ({
    type: CLEAR_ORGS,
  })

}