import Immutable from 'seamless-immutable'
import * as OrgActionTypes from './actions'
import * as AuthActionTypes from '../auth/actions'
import * as InviteActionTypes from '../invites/actions'

/* ------------- Initial State ------------- */
const INITIAL_STATE = Immutable({
  fetching: false,
  create_fetching: false,
  update_fetching: false,
  delete_fetching: false,
  details_fetching: false,
  update_member_fetching: false,
  delete_member_fetching: false,
  error: null,
  create_error: null,
  update_error: null,
  delete_error: null,
  details_error: null,
  update_member_error: null,
  delete_member_error: null,
  org_list: [],
  invite_list: [],
  selected_org: null,
  org_details: null,
})

/* ------------- Reducer ------------- */
export function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    case OrgActionTypes.FETCH_ORGS_REQUEST:
      return state.merge({
        fetching: true,
        error: null,
      })

    case OrgActionTypes.FETCH_ORGS_SUCCESS:
      return state.merge({
        org_list: payload.data.orgs,
        invite_list: payload.data.invites,
        fetching: false,
      })

    case OrgActionTypes.FETCH_ORGS_FAILURE:
      return state.merge({
        fetching: false,
        error: payload.error,
      })

    case OrgActionTypes.ORG_DETAILS_REQUEST:
      return state.merge({
        details_fetching: true,
        details_error: null,
      })

    case OrgActionTypes.ORG_DETAILS_SUCCESS:
      return state.merge({
        details_fetching: false,
        org_details: payload.org,
      })

    case OrgActionTypes.ORG_DETAILS_FAILURE:
      return state.merge({
        details_fetching: false,
        details_error: payload.error,
      })

    case OrgActionTypes.ADD_ORG_REQUEST:
      return state.merge({
        create_fetching: true,
        create_error: null,
      })

    case OrgActionTypes.ADD_ORG_SUCCESS:
      return state.merge({
        create_fetching: false,
      })

    case OrgActionTypes.ADD_ORG_FAILURE:
      return state.merge({
        create_fetching: false,
        create_error: payload.error,
      })

    case OrgActionTypes.UPDATE_ORG_REQUEST:
      return state.merge({
        update_fetching: true,
        update_error: null,
      })

    case OrgActionTypes.UPDATE_ORG_SUCCESS:
      return state.merge({
        update_fetching: false
      })

    case OrgActionTypes.UPDATE_ORG_FAILURE:
      return state.merge({
        update_fetching: false,
        update_error: payload.error,
      })

    case OrgActionTypes.DELETE_ORG_REQUEST:
      return state.merge({
        delete_fetching: true,
        delete_error: null,
      })

    case OrgActionTypes.DELETE_ORG_SUCCESS:
      return state.merge({
        delete_fetching: false,
      })

    case OrgActionTypes.DELETE_ORG_FAILURE:
      return state.merge({
        delete_fetching: false,
        delete_error: payload.error,
      })

    case OrgActionTypes.UPDATE_ORG_MEMBER_REQUEST:
      return state.merge({
        update_member_fetching: true,
        update_member_error: null,
      })

    case OrgActionTypes.UPDATE_ORG_MEMBER_SUCCESS:
      return state.merge({
        update_member_fetching: false,
        org_details: state.org_details.users
          ? {
              ...state.org_details,
              users: state.org_details.users.map((user) => {
                      if (user.id === payload.orgMember.id)
                        return payload.orgMember
                      else
                        return user
                    })
            }
          : null
      })

    case OrgActionTypes.UPDATE_ORG_MEMBER_FAILURE:
      return state.merge({
        update_member_fetching: false,
        update_member_error: payload.error
      })

    case OrgActionTypes.DELETE_ORG_MEMBER_REQUEST:
      return state.merge({
        delete_member_fetching: true,
        delete_member_error: null,
      })

    case OrgActionTypes.DELETE_ORG_MEMBER_SUCCESS:
      return state.merge({
        delete_member_fetching: false,
        org_details: state.org_details.users
          ? {
              ...state.org_details,
              users: state.org_details.users.filter((user) => user.id !== payload.userId)
            }
          : null
      })

    case OrgActionTypes.DELETE_ORG_MEMBER_FAILURE:
      return state.merge({
        delete_member_fetching: false,
        delete_member_error: payload.error,
      })

    case OrgActionTypes.SELECT_ORG:
      return state.merge({
        selected_org: payload.orgId,
      })

    case AuthActionTypes.LOGOUT:
      return INITIAL_STATE

    case InviteActionTypes.ACCEPT_INVITE_SUCCESS:
      return state.merge({
        org_list: [...state.org_list,payload.org],
        invite_list: state.invite_list.filter((invite) => invite.org_id !== payload.org.id)
      })

    case InviteActionTypes.DECLINE_INVITE_SUCCESS:
      return state.merge({
        invite_list: state.invite_list.filter((invite) => invite.id !== payload.invite.id)
      })

    case InviteActionTypes.RESEND_INVITE_SUCCESS:
      return state.merge({
        org_details: state.org_details.invites
          ? {
              ...state.org_details,
              invites: state.org_details.invites.map((invite) => {
                if (invite.id === payload.inviteId)
                  return payload.invite
                else
                  return invite
              })
            }
          : null
      })

    default:
      return state

  }
}