import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Button, Spinner, Alert } from 'react-bootstrap'
import Styled from 'styled-components'
import StackLogo from '../../../assets/images/Stack_Sports_logo_41.png'

class ForgotPasswordScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: null,
      validated: false,
      sentSuccess: false,
    }

    this.validateEmail = this.validateEmail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      this.setState({email: email})
    } else {
      this.setState({email: null})
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const { email } = this.state
    this.props.forgotPassword(email)

  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetching && !this.props.fetching && !this.props.error)
      this.setState({sentSuccess: true})
  }

  render() {

    const { fetching, error, history } = this.props
    const { validated, email, sentSuccess } = this.state

    return(
      <MainContainer>
        <img style={{marginRight: '10px'}} src={StackLogo} alt="stack logo" />
        <div style={{fontSize: '20px', fontWeight: 'bold', paddingTop: '20px'}}>StackStream MVP Demo</div>
        <div>Internal Use Only - v{process.env.REACT_APP_VERSION}</div>

        <div style={{fontSize: '20px', fontWeight: 'bold', paddingTop: '20px'}}>Password Reset</div>

        <Form style={{paddingTop: '30px'}} noValidate validated={validated} onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Control
              type="text"
              required
              placeholder="Email"
              onChange={(e) => this.validateEmail(e.target.value)}
              style={{width: '200px'}}
            />
            <Form.Control.Feedback type="invalid">
              Please enter your email address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Button variant="primary" type="submit" disabled={(!email || fetching)} onClick={this.handleSubmit} style={{width: '100%'}}>
              {fetching
                ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Working...</Fragment>
                : "Submit"
              }
            </Button>
          </Form.Group>
          <Button variant="secondary" style={{width: '100%'}} onClick={() => history.goBack()}>
            Back
          </Button>
        </Form>

        {error &&
          <Alert variant="danger" style={{marginTop: '36px'}}>
            {error}
          </Alert>
        }

        { sentSuccess &&
          <Alert variant="success" style={{marginTop: '36px'}}>
            If your account was found, we sent you a link to reset your password.
          </Alert>
        }

      </MainContainer>
    )
  }

}

const MainContainer = Styled.div`
  color: #FFF;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export default withRouter(ForgotPasswordScreen)