import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Styled from 'styled-components'
import { Button, Spinner } from 'react-bootstrap'
import AddEventModal from '../../../components/AddEventModal'
import ConfirmationModal from '../../../components/ConfirmationModal'
import FailureModal from '../../../components/FailureModal'
import EventListItem from '../../../components/EventListItem'

class EventListScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      addEventModalOpen: false,
      eventToRemove: null,
      modalError: null
    }
    this.removeEvent = this.removeEvent.bind(this)
    this.failModal = this.failModal.bind(this)
    this.createEvent = this.createEvent.bind(this)
  }

  componentDidMount() {
    if (this.props.selectedOrg)
      this.props.fetchEvents(this.props.selectedOrg)
    else
      this.props.fetchOrgs()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOrg !== this.props.selectedOrg)
      this.props.fetchEvents(this.props.selectedOrg)
  }

  confirmRemoveEvent(event) {
    this.setState({eventToRemove: event})
  }
  removeEvent() {
    this.props.deleteEvent(this.state.eventToRemove.id)
  }
  failModal(error) {
    this.setState({modalError: error})
    this.props.fetchEvents(this.props.selectedOrg)
  }

  createEvent(event) {
    // append selected org from state
    this.props.createEvent({...event,org_id: this.props.selectedOrg})
  }

  render() {

    const { eventList, fetching, addOrg, orgsFetching, selectedOrg, orgList, history } = this.props

    return (

      <Fragment>

      <ContentContainer>

        <EventListContainer>

          <h2 style={{color: '#FFF', marginBottom: '20px'}}>My Events</h2>

          {fetching || orgsFetching
            ? <Spinner animation="border" style={{color: '#FFF', alignSelf: 'center', marginTop: '50px'}} />
            : (eventList.length > 0)
              ? eventList.map(event => {
                  return(<EventListItem
                    key={event.id}
                    name={event.name}
                    location={event.location}
                    start_time={event.start_time}
                    end_time={event.end_time}
                    camera_count={event.camera_count}
                    stream_status={event.stream_status}
                    onRemove={() => this.setState({eventToRemove: event})}
                    onSelect={() => history.push(`/event/${event.id}`)}
                    disabled={this.props.selectedEvent ? true : false}
                    working={!this.props.eventJoined && this.props.selectedEvent && (this.props.selectedEvent.id === event.id)}
                  />)
                })
              : (orgList.length > 0)
                ? <div style={{color: '#FFF', marginTop: '50px', textAlign: 'center'}}>No Events Found</div>
                : <div style={{display: 'flex', flexDirection: 'column', color: '#FFF', marginTop: '50px', textAlign: 'center'}}>
                    Create an organization to get started!
                    <Button onClick={addOrg} style={{marginTop: '20px'}}>Create New Org</Button>
                  </div>
          }

        </EventListContainer>

        { (orgList.length > 0) &&
          <div style={{width: '100%'}}>
          <StyledButton disabled={eventList.length > 19} onClick={() => this.setState({addEventModalOpen: true})}>Add Event</StyledButton>
          </div>
        }

        <AddEventModal
          show={this.state.addEventModalOpen}
          onHide={() => this.setState({addEventModalOpen: false})}
          handleSubmit={this.createEvent}
          working={this.props.createFetching}
          error={this.props.createError}
          onSuccess={() => this.props.fetchEvents(selectedOrg)}
        />
        <ConfirmationModal
          title={'Remove Event'}
          buttonLabel={'Remove'}
          show={this.state.eventToRemove !== null ? true : false}
          onHide={() => this.setState({eventToRemove: null})}
          message={this.state.eventToRemove ? "Are you sure you want to remove '"+this.state.eventToRemove.name+"'?" : null}
          handleSubmit={this.removeEvent}
          working={this.props.deleteFetching}
          error={this.props.deleteError}
          onSuccess={() => this.props.fetchEvents(selectedOrg)}
          onFail={this.failModal}
        />
        <FailureModal
          show={this.state.modalError !== null}
          onHide={() => this.setState({modalError: null})}
          message={this.state.modalError}
        />

      </ContentContainer>

      </Fragment>

    )
  }

}

const StyledButton = Styled(Button)`
  width: 100%;
  -webkit-flex: 0;
  flex-shrink: 0;
`

const EventListContainer = Styled.div`
  overflow-y: auto;
  margin-bottom: 20px;
`

const ContentContainer = Styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  background-color: #071743;
  overflow: hidden;
`

export default withRouter(EventListScreen)