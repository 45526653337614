import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap'

class FailureModal extends Component {


  render() {

    return(
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        centered
        keyboard={true}
      >
        <Modal.Header>
          <Modal.Title>Oops!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{this.props.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>Ok</Button>
        </Modal.Footer>
      </Modal>
    )
  }

}

export default FailureModal