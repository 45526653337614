import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Styled from 'styled-components'
import { Alert } from 'react-bootstrap'
import StackLogo from '../../../assets/images/Stack_Sports_logo_41.png'
import SignupForm from './SignupForm'
import RegistrationForm from './RegistrationForm'
import PasswordForm from './PasswordForm'

class SignupScreen extends Component {

  componentDidUpdate() {
    // redirect to app on successful auth of existing stackstream user
    if (this.props.accessToken && this.props.existingUser) {
      this.props.history.replace("/")
    }
  }

  render() {

    const {
      authFetching,
      authError,
      accessToken,
      signupFetching,
      signupError,
      signupEmail,
      ssoStatus,
      checkSignup,
      login,
      register,
      registerFetching,
      registerError,
      clearSignup,
    } = this.props

    return(
      <MainContainer>
        <img style={{marginRight: '10px'}} src={StackLogo} alt="stack logo" />
        <div style={{fontSize: '20px', fontWeight: 'bold', paddingTop: '20px'}}>StackStream MVP Demo</div>
        <div>Internal Use Only - v{process.env.REACT_APP_VERSION}</div>

        <div style={{fontSize: '20px', fontWeight: 'bold', paddingTop: '20px'}}>Registration</div>

        { (!signupEmail || signupFetching) &&
          <SignupForm
            onSubmit={checkSignup}
            fetching={signupFetching}
          />
        }

        { signupEmail && ssoStatus && !accessToken &&
          <PasswordForm
            signupEmail={signupEmail}
            onSubmit={login}
            onReset={clearSignup}
            fetching={authFetching}
          />
        }

        { !signupFetching &&
          !authFetching &&
          ((signupEmail && !ssoStatus) || (signupEmail && ssoStatus && accessToken)) &&
          <RegistrationForm
            signupEmail={signupEmail}
            ssoStatus={ssoStatus}
            onSubmit={register}
            onReset={clearSignup}
            fetching={registerFetching}
            token={accessToken}
          />
        }

        {signupError &&
          <Alert variant="danger" style={{marginTop: '20px'}}>{signupError}</Alert>
        }
        {authError &&
          <Alert variant="danger" style={{marginTop: '20px'}}>{authError}</Alert>
        }
        {registerError &&
          <Alert variant="danger" style={{marginTop: '20px'}}>{registerError}</Alert>
        }

      </MainContainer>
    )
  }

}

const MainContainer = Styled.div`
  color: #FFF;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export default withRouter(SignupScreen)