import { call, put, } from 'redux-saga/effects'
import api from '../../services/api'
import ActionCreators from './actions'
import EventActionCreators from '../events/actions'

export function* fetchOrgs({ payload: { selectedOrg }}) {
  try {
    const response = yield call(api.orgs.getAll)
    if (response && response.status === 200) {

      yield put(ActionCreators.fetchOrgsSuccess(response.data))

      // check to see if selected org needs to change based on result
      if (response.data.orgs.length > 0) {
        const newOrgIds = response.data.orgs.map((org) => {
          return org.id
        })
        if (!selectedOrg || !newOrgIds.includes(selectedOrg)) {
          yield put(ActionCreators.selectOrg(response.data.orgs[0].id))
        }
      } else {
        yield put(ActionCreators.selectOrg(null))
        yield put(EventActionCreators.clearEvents())
      }


    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.fetchOrgsFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.fetchOrgsFailure(message))
  }
}

export function* fetchOrgDetails({ payload: { orgId }}) {
  try {
    const response = yield call(api.orgs.getOrg, orgId)
    if (response && response.status === 200) {

      yield put(ActionCreators.orgDetailsSuccess(response.data))


    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.orgDetailsFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.orgDetailsFailure(message))
  }
}

export function* addOrg({ payload: { name }}) {
  try {
    const response = yield call(api.orgs.createOrg, name)
    if (response && response.status === 200) {

      yield put(ActionCreators.addOrgSuccess(response.data))


    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.addOrgFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.addOrgFailure(message))
  }
}

export function* updateOrg({ payload: { orgId, name }}) {
  try {
    const response = yield call(api.orgs.updateOrg, orgId, name)
    if (response && response.status === 200) {

      yield put(ActionCreators.updateOrgSuccess(response.data))


    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.updateOrgFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.updateOrgFailure(message))
  }
}

export function* deleteOrg({ payload: { orgId }}) {
  try {
    const response = yield call(api.orgs.deleteOrg, orgId)
    if (response && response.status === 200) {

      yield put(ActionCreators.deleteOrgSuccess(response.data))


    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.deleteOrgFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.deleteOrgFailure(message))
  }
}

export function* updateOrgMember({ payload: { orgId, userId, role }}) {
  try {
    const response = yield call(api.orgs.updateMember, orgId, userId, role)
    if (response && response.status === 200) {

      yield put(ActionCreators.updateOrgMemberSuccess(response.data))


    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.updateOrgMemberFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.updateOrgMemberFailure(message))
  }
}

export function* deleteOrgMember({ payload: { orgId, userId }}) {
  try {
    const response = yield call(api.orgs.deleteMember, orgId, userId)
    if (response && response.status === 200) {

      yield put(ActionCreators.deleteOrgMemberSuccess(userId))


    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.deleteOrgMemberFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.deleteOrgMemberFailure(message))
  }
}

