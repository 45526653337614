import Immutable from 'seamless-immutable'
import * as InviteActionTypes from './actions'

/* ------------- Initial State ------------- */
const INITIAL_STATE = Immutable({
  create_fetching: false,
  accept_decline_fetching: [],
  resend_fetching: [],
  revoke_fetching: [],
  create_error: null,
  accept_decline_error: null,
  resend_error: [],
  revoke_error: [],
})

/* ------------- Reducer ------------- */
export function reducer(state = INITIAL_STATE, { type, payload }) {

  switch (type) {

    case InviteActionTypes.ORG_INVITE_REQUEST:
      return state.merge({
        create_fetching: true,
        create_error: null,
      })

    case InviteActionTypes.ORG_INVITE_SUCCESS:
      return state.merge({
        create_fetching: false,
      })

    case InviteActionTypes.ORG_INVITE_FAILURE:
      return state.merge({
        create_fetching: false,
        create_error: payload.error,
      })

    case InviteActionTypes.RESEND_INVITE_REQUEST:
      return state.merge({
        resend_fetching: [...state.resend_fetching,payload.inviteId],
        resend_error: state.resend_fetching.map((item) => item.id !== payload.inviteId),
      })

    case InviteActionTypes.RESEND_INVITE_SUCCESS:
      return state.merge({
        resend_fetching: state.resend_fetching.filter((item) => item !== payload.inviteId),
      })

    case InviteActionTypes.RESEND_INVITE_FAILURE:
      return state.merge({
        resend_fetching: state.resend_fetching.filter((item) => item !== payload.inviteId),
        resend_error: [...state.resend_error,{id: payload.inviteId, error: payload.error}],
      })

    case InviteActionTypes.REVOKE_INVITE_REQUEST:
      return state.merge({
        revoke_fetching: [...state.revoke_fetching,payload.inviteId],
        revoke_error: state.revoke_fetching.map((item) => item.id !== payload.inviteId),
      })

    case InviteActionTypes.REVOKE_INVITE_SUCCESS:
      return state.merge({
        revoke_fetching: state.revoke_fetching.filter((item) => item !== payload.inviteId),
      })

    case InviteActionTypes.REVOKE_INVITE_FAILURE:
      return state.merge({
        revoke_fetching: state.revoke_fetching.filter((item) => item !== payload.inviteId),
        revoke_error: [...state.revoke_error,{id: payload.inviteId, error: payload.error}],
      })

    case InviteActionTypes.ACCEPT_INVITE_REQUEST:
    case InviteActionTypes.DECLINE_INVITE_REQUEST:
      return state.merge({
        accept_decline_fetching: [...state.accept_decline_fetching,payload.code],
        accept_decline_error: null,
      })

    case InviteActionTypes.ACCEPT_INVITE_SUCCESS:
    case InviteActionTypes.DECLINE_INVITE_SUCCESS:
      return state.merge({
        accept_decline_fetching: state.accept_decline_fetching.filter((item) => item !== payload.code),
      })

    case InviteActionTypes.ACCEPT_INVITE_FAILURE:
    case InviteActionTypes.DECLINE_INVITE_FAILURE:
      return state.merge({
        accept_decline_fetching: state.accept_decline_fetching.filter((item) => item !== payload.code),
        accept_decline_error: payload.error,
      })

    default:
      return state

  }
}