import { connect } from 'react-redux'
import SignupScreenView from '../components/SignupScreenView'
import AuthActions from '../../../modules/auth/actions'

export const mapStateToProps = ({ auth }) => {
  return {
    authFetching: auth.fetching,
    authError: auth.error,
    accessToken: auth.access_token,
    signupFetching: auth.signup_fetching,
    signupSuccess: auth.signup_success,
    signupError: auth.signup_error,
    signupEmail: auth.email,
    ssoStatus: auth.sso_status,
    existingUser: auth.existing_user,
    registerFetching: auth.register_fetching,
    registerError: auth.register_error,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch(AuthActions.authRequest(email,password)),
  clearSignup: () => dispatch(AuthActions.clearSignup()),
  checkSignup: (email) => dispatch(AuthActions.signupCheckRequest(email)),
  register: (email,password,firstName,lastName,token,betaCode) => dispatch(AuthActions.registerRequest(email,password,firstName,lastName,token,betaCode)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignupScreenView)