import React, { Component, Fragment } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'

class SignupForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      password: null,
      validated: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const { password } = this.state
    const { signupEmail } = this.props
    this.props.onSubmit(signupEmail,password)

  }

  render() {

    const { validated, password } = this.state
    const { fetching, signupEmail, onReset } = this.props

    return(

      <Fragment>

      <div style={{display: 'flex', flexDirection: 'column', paddingTop: '20px', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{fontSize: '18px'}}>Looks like you already have an account with Stack Sports</div>
        <div>Now you only need one account for all of Stack Sports' services!</div>
      </div>

      <Form style={{paddingTop: '30px'}} noValidate validated={validated} onSubmit={this.handleSubmit}>
        <Form.Group>
          <Form.Control
            type="text"
            required
            value={signupEmail}
            disabled={true}
            style={{width: '200px'}}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="password"
            required
            placeholder="Password"
            onChange={(e) => this.setState({password: e.target.value})}
            style={{width: '200px'}}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a password.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Button variant="primary" type="submit" disabled={(!password || fetching)} onClick={this.handleSubmit} style={{width: '100%'}}>
            {fetching
              ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Verifying...</Fragment>
              : "Next"
            }
          </Button>
        </Form.Group>
        <Button variant="secondary" onClick={onReset} style={{width: '100%'}}>Back</Button>
      </Form>

      </Fragment>

    )
  }

}

export default SignupForm