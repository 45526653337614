import { connect } from 'react-redux'
import BroadcastScreenView from '../components/BroadcastScreenView'
import SelectedEventActions from '../../../modules/selected_event/actions'
import CameraActions from '../../../modules/cameras/actions'
import AuthActions from '../../../modules/auth/actions'

export const mapStateToProps = ({ auth, selected_event, cameras }) => {
  return {
    fetching: selected_event.fetching,
    error: selected_event.error,
    event: selected_event.event,
    accessToken: auth.access_token,
    videoIdentity: auth.video_service_identity,
    videoToken: auth.video_token,
    cameraList: cameras.camera_list,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  fetchEvent: (eventId) => dispatch(SelectedEventActions.fetchEventRequest(eventId)),
  refreshVideoToken: () => dispatch(AuthActions.videoAuthRequest()),
  login: (email, password) => dispatch(AuthActions.authRequest(email,password)),
  fetchCameras: (eventId) => dispatch(CameraActions.fetchCamerasRequest(eventId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastScreenView)