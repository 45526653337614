import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { api } from '../services/api'
import StartupActionCreators from '../modules/startup/actions'
import ImmutablePersistenceTransform from '../services/utils/ImmutablePersistenceTransform'
import { InterceptorMiddleware } from '../services/utils/InterceptorMiddleware'
import { migrations } from './migrations'

// Redux Store Creation
export default(rootReducer, rootSaga) => {

  /* ------------- Redux Configuration ------------- */
  const middleware = []
  const enhancers = []

  const sagaMiddleware = createSagaMiddleware()
  middleware.push(sagaMiddleware)

  /* ----------- Api Middleware -------------------- */
  middleware.push(InterceptorMiddleware(api))

  /* ------------- Assemble Middleware ------------- */
  enhancers.push(applyMiddleware(...middleware))

  // User redux devtools compose if redux devtools are installed. This will enable redux logger
  const composeEnhancers = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose

  // add persist
  const persistConfig = {
    key: 'root',
    //version: 1,
    storage,
    whitelist: ['auth','orgs'],
    migrate: createMigrate(migrations, { debug: false }),
    transforms: [ImmutablePersistenceTransform],
  }
  const persistedReducer = persistReducer(persistConfig,rootReducer)

  // create store
  const store = createStore(persistedReducer, composeEnhancers(...enhancers))
  let persistor = persistStore(store)

  store.asyncReducers = {}
  store.runSaga = sagaMiddleware.run

  // inject sagas into middleware
  sagaMiddleware.run(rootSaga, store)

  // dispatch startup actions
  store.dispatch(StartupActionCreators.startup())

  return { store, persistor }

}