import React, { Component, Fragment } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap'

class ConfirmationModal extends Component {

  constructor(props) {
    super(props)

    this.confirm = this.confirm.bind(this)
  }

  confirm() {
    if (!this.props.working) {
      this.props.handleSubmit()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.working && !this.props.working) {
      if (!this.props.error) {
        this.props.onHide()
        this.props.onSuccess()
      } else {
        this.props.onHide()
        this.props.onFail(this.props.error)
      }
    }
  }

  render() {

    const { working, title, buttonLabel } = this.props

    return(
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        centered
        keyboard={true}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{this.props.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onHide}>Cancel</Button>
          <Button type="submit" variant="danger" disabled={working} onClick={this.confirm}>
            {working
              ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Working...</Fragment>
              : <Fragment>{buttonLabel}</Fragment>
            }
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

}

export default ConfirmationModal