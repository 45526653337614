import { call, put, } from 'redux-saga/effects'
import api from '../../services/api'
import ActionCreators from './actions'

export function* fetchEvents({ payload: { orgId }}) {
  try {
    const response = yield call(api.events.getAll,orgId)
    if (response && response.status === 200) {

      yield put(ActionCreators.fetchEventsSuccess(response.data))
    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.fetchEventsFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.fetchEventsFailure(message))
  }
}

export function* addEvent({ payload: { event }}) {
  try {
    const response = yield call(api.events.createEvent, event)
    if (response && response.status === 200) {

      yield put(ActionCreators.addEventSuccess(response.data))
    } else {
      const message = 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.addEventFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.addEventFailure(message))
  }
}

export function* deleteEvent({ payload: { eventId }}) {
  try {
    const response = yield call(api.events.deleteEvent, eventId)
    if (response && response.status === 200) {

      yield put(ActionCreators.deleteEventSuccess(response.data))
    } else {

      const message = response.data.message ? response.data.message : 'Oops! Something went wrong, please try again'

      yield put(ActionCreators.deleteEventFailure(message))
    }

  } catch (e) {

    let message = ""
    if (!e.response) {
      message = 'Please check your internet connection and try again'
    } else if (e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0].detail
    } else {
      message = 'Oops! Something went wrong, please try again'
    }

    yield put(ActionCreators.deleteEventFailure(message))
  }
}



