import { connect } from 'react-redux'
import EventListScreenView from '../components/EventListScreenView'
import EventActions from '../../../modules/events/actions'
import OrgActions from '../../../modules/orgs/actions'

export const mapStateToProps = ({ events, orgs }) => {
  return {
    fetching: events.fetching,
    error: events.error,
    createFetching: events.create_fetching,
    createError: events.create_error,
    deleteFetching: events.delete_fetching,
    deleteError: events.delete_error,
    eventList: events.event_list,
    orgsFetching: orgs.fetching,
    selectedOrg: orgs.selected_org,
    orgList: orgs.org_list,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  fetchEvents: (orgId) => dispatch(EventActions.fetchEventsRequest(orgId)),
  createEvent: (event) => dispatch(EventActions.addEventRequest(event)),
  deleteEvent: (eventId) => dispatch(EventActions.deleteEventRequest(eventId)),
  fetchOrgs: () => dispatch(OrgActions.fetchOrgsRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventListScreenView)