export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST'
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS'
export const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE'
export const DESELECT_EVENT = 'DESELECT_EVENT'

export default {

  fetchEventRequest: (eventId) => ({
    type: FETCH_EVENT_REQUEST,
    payload: {
      eventId,
    },
  }),

  fetchEventSuccess: (event) => ({
    type: FETCH_EVENT_SUCCESS,
    payload: {
      event,
    },
  }),

  fetchEventFailure: (error) => ({
    type: FETCH_EVENT_FAILURE,
    payload: {
      error,
    },
  }),

  deselectEvent: () => ({
    type: DESELECT_EVENT,
  })

}