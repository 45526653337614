import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Card, Spinner, ListGroup } from 'react-bootstrap'
import moment from 'moment'
import Styled from 'styled-components'
import EditOrgModal from './EditOrgModal'
import EditMemberModal from './EditMemberModal'
import InviteModal from './InviteModal'
import MemberListItem from './MemberListItem'
import ConfirmationModal from '../../../components/ConfirmationModal'
import FailureModal from '../../../components/FailureModal'

class OrgManagementScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      editOrgModalOpen: false,
      memberToEdit: null,
      memberToDelete: null,
      inviteModalOpen: false,
      orgToRemove: null,
      inviteToRevoke: null,
      modalError: null,
    }

    this.verifyOrgPermissions = this.verifyOrgPermissions.bind(this)
    this.returnToEventList = this.returnToEventList.bind(this)
    this.updateOrg = this.updateOrg.bind(this)

    this.removeOrg = this.removeOrg.bind(this)
    this.failModal = this.failModal.bind(this)
    this.removeOrgSuccess = this.removeOrgSuccess.bind(this)

    this.sendInvite = this.sendInvite.bind(this)
    this.inviteSuccess = this.inviteSuccess.bind(this)

    this.revokeInvite = this.revokeInvite.bind(this)
    this.revokeInviteSuccess = this.revokeInviteSuccess.bind(this)

    this.updateOrgMember = this.updateOrgMember.bind(this)

    this.deleteOrgMember = this.deleteOrgMember.bind(this)

  }

  componentDidMount() {

    const orgId = this.props.match.params.id

    if (!this.props.selectedOrg)
      this.props.fetchOrgs()

    // check that we have admin permissions
    if (this.verifyOrgPermissions(orgId))
      this.props.fetchOrgDetails(orgId)
    else
      this.returnToEventList()
  }

  componentDidUpdate(prevProps) {
    // change route if new org is selected from dropdown
    if (prevProps.selectedOrg !== this.props.selectedOrg) {
      this.props.history.replace(`/org/${this.props.selectedOrg}`)
    }
    // if the org changes, make sure we still have admin permissions
    if (prevProps.match.params.id !== this.props.match.params.id) {
      const orgId = this.props.match.params.id
      if (this.verifyOrgPermissions(orgId))
        this.props.fetchOrgDetails(orgId)
      else
        this.returnToEventList()
    }
  }

  verifyOrgPermissions(orgId) {
    const selectedOrgFromList = this.props.orgs.find((org) => org.id === orgId)
    const userRole = selectedOrgFromList ? selectedOrgFromList.role : "USER"
    return userRole === "ADMIN"
  }

  returnToEventList() {
    this.props.history.push('/')
  }

  updateOrg(name) {
    const orgId = this.props.match.params.id
    this.props.updateOrg(orgId,name)
  }


  removeOrg() {
    this.props.deleteOrg(this.state.orgToRemove.id)
  }
  failModal(error) {
    this.setState({modalError: error})
  }
  removeOrgSuccess() {
    this.props.fetchOrgs()
  }

  sendInvite(email,role) {
    const orgId = this.props.match.params.id
    this.props.sendInvite(orgId,email,role)
  }
  inviteSuccess() {
    const orgId = this.props.match.params.id
    this.props.fetchOrgDetails(orgId)
  }

  revokeInvite() {
    this.props.revokeInvite(this.state.inviteToRevoke.id)
  }
  revokeInviteSuccess() {
    const orgId = this.props.match.params.id
    this.props.fetchOrgDetails(orgId)
  }

  updateOrgMember(role) {
    const orgId = this.props.match.params.id
    const { memberToEdit } = this.state
    this.props.updateOrgMember(orgId,memberToEdit.id,role)
  }
  deleteOrgMember() {
    const orgId = this.props.match.params.id
    const { memberToDelete } = this.state
    this.props.deleteOrgMember(orgId,memberToDelete.id)
  }

  render() {

    const orgId = this.props.match.params.id
    const {
      fetching,
      inviteResendFetching,
      orgDetails,
      resendInvite
    } = this.props

    const {
      inviteToRevoke,
      memberToDelete
    } = this.state

    return (
      <Fragment>

        <ContentContainer>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div><h2 style={{color: '#FFF', marginBottom: '20px'}}>Organization Details</h2></div>
          <div><Button onClick={this.returnToEventList}>Back to Events</Button></div>
        </div>

        { (fetching || !orgDetails)
          ? <Spinner animation="border" style={{color: '#FFF', alignSelf: 'center', marginTop: '50px'}} />
          : <Fragment>

            <Card style={{width: '100%', marginBottom: '5px'}}>
              <Card.Body>
                <Card.Title>{orgDetails.name}</Card.Title>
                <div style={{margin: '0 0 20px 0'}}>
                  <div><span style={{fontWeight: 'bold'}}>Created:</span> {moment(orgDetails.created_at).format('dddd MMMM Do YYYY, h:mma')}</div>
                </div>
                <Button
                  variant="danger"
                  onClick={() => this.setState({orgToRemove: orgDetails})}
                >
                  Delete Organization
                </Button>
                <Button variant="success" style={{marginLeft: '10px'}} onClick={() => this.setState({editOrgModalOpen: true})}>Edit</Button>
              </Card.Body>
            </Card>

            <div style={{display: 'flex', justifyContent: 'space-between', margin: '10px 0 10px 0'}}>
              <div><h2 style={{color: '#FFF'}}>Members</h2></div>
              <div><Button onClick={() => this.setState({inviteModalOpen: true})}>Add Member</Button></div>
            </div>

            <div style={{flex: 1, overflowY: 'auto'}}>
              <ListGroup>
              { orgDetails.users.map((user) => {
                  return (
                    <MemberListItem
                      key={user.id}
                      type="user"
                      item={user}
                      editMember={() => this.setState({memberToEdit: user})}
                      deleteMember={() => this.setState({memberToDelete: user})}
                      currentUser={this.props.authdEmail}
                    />
                  )
                })
              }
              </ListGroup>
              { orgDetails.invites &&
                <Fragment>
                  <div style={{display: 'flex', justifyContent: 'space-between', margin: '10px 0 10px 0'}}>
                    <div><h2 style={{color: '#FFF'}}>Invitations</h2></div>
                  </div>
                  <ListGroup>
                  { orgDetails.invites.map((invite) => {
                      return (
                        <MemberListItem
                          key={invite.id}
                          type="invite"
                          item={invite}
                          resendInvite={() => resendInvite(invite.id)}
                          revokeInvite={() => this.setState({inviteToRevoke: invite})}
                          inviteResendFetching={inviteResendFetching.includes(invite.id)}
                        />
                      )
                    })
                  }
                  </ListGroup>
                </Fragment>
              }
            </div>

            </Fragment>

        }

        <EditOrgModal
          currentValue={orgDetails ? orgDetails.name : null}
          show={this.state.editOrgModalOpen}
          onHide={() => this.setState({editOrgModalOpen: false})}
          handleSubmit={this.updateOrg}
          working={this.props.updateFetching}
          error={this.props.updateError}
          onSuccess={() => {
            this.props.fetchOrgDetails(orgId)
            this.props.fetchOrgs()
          }}
        />
        <InviteModal
          show={this.state.inviteModalOpen}
          onHide={() => this.setState({inviteModalOpen: false})}
          handleSubmit={this.sendInvite}
          working={this.props.inviteCreateFetching}
          error={this.props.inviteCreateError}
          onSuccess={this.inviteSuccess}
        />
        <EditMemberModal
          show={this.state.memberToEdit !== null ? true : false}
          onHide={() => this.setState({memberToEdit: null})}
          orgMember={this.state.memberToEdit}
          handleSubmit={this.updateOrgMember}
          working={this.props.updateOrgMemberFetching}
          error={this.props.updateOrgMemberError}
          onSuccess={() => {}}
        />
        <ConfirmationModal
          title={'Delete Organization'}
          buttonLabel={'Delete'}
          show={this.state.orgToRemove !== null ? true : false}
          onHide={() => this.setState({orgToRemove: null})}
          message={this.state.orgToRemove ? "Are you sure you want to remove '"+this.state.orgToRemove.name+"'? This will delete all associated events and can not be undone." : null}
          handleSubmit={this.removeOrg}
          working={this.props.deleteFetching}
          error={this.props.deleteError}
          onSuccess={this.removeOrgSuccess}
          onFail={this.failModal}
        />
        <ConfirmationModal
          title={'Revoke Invitation'}
          buttonLabel={'Revoke'}
          show={inviteToRevoke !== null ? true : false}
          onHide={() => this.setState({inviteToRevoke: null})}
          message={inviteToRevoke ? "Are you sure you want to revoke the invitation to '"+this.state.inviteToRevoke.email+"'?" : null}
          handleSubmit={this.revokeInvite}
          working={inviteToRevoke ? this.props.inviteRevokeFetching.includes(inviteToRevoke.id) : null}
          error={inviteToRevoke ? this.props.inviteRevokeError[this.state.inviteToRevoke.id] : null}
          onSuccess={this.revokeInviteSuccess}
          onFail={this.failModal}
        />
        <ConfirmationModal
          title={'Remove Member'}
          buttonLabel={'Remove'}
          show={memberToDelete !== null ? true : false}
          onHide={() => this.setState({memberToDelete: null})}
          message={memberToDelete ? "Are you sure you want to remove '"+this.state.memberToDelete.email+"' from this organization?" : null}
          handleSubmit={this.deleteOrgMember}
          working={memberToDelete ? this.props.deleteOrgMemberFetching : null}
          error={memberToDelete ? this.props.deleteOrgMemberError : null}
          onSuccess={() => {}}
          onFail={this.failModal}
        />
        <FailureModal
          show={this.state.modalError !== null}
          onHide={() => this.setState({modalError: null})}
          message={this.state.modalError}
        />

        </ContentContainer>

      </Fragment>
    )
  }

}

const ContentContainer = Styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  background-color: #071743;
  overflow: hidden;
`

export default withRouter(OrgManagementScreen)
