import React, { Component, Fragment } from 'react'
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap'

class VerifyEmailModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      verificationCode: '',
      validated: false,
      resendSuccess: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleResend = this.handleResend.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()

    if (!this.props.working) {
      this.setState({validated: true})
      const { verificationCode } = this.state
      const { email } = this.props
      if (verificationCode !== '') {
        this.props.handleSubmit(email,verificationCode)
      }
    }

  }

  handleResend(event) {
    event.preventDefault()
    event.stopPropagation()

    const { email } = this.props
    this.props.handleResend(email)

  }

  componentDidUpdate(prevProps) {
    // check if resend was successful
    if (prevProps.resendWorking && !this.props.resendWorking && !this.props.resendError)
      this.setState({resendSuccess: true})
    if ((!prevProps.error && this.props.error) || (!prevProps.resendError && this.props.resendError))
      this.setState({resendSuccess: false})
  }

  render() {

    const { validated, resendSuccess } = this.state
    const { email, working, error, onLogout, resendWorking, resendError } = this.props

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        centered
        keyboard={true}
      >
        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Welcome!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Before you can begin using StackStream, you need to verify your email address.
          </p>
          <p>
            You should have received a validation code at the email address you provided.
            Please enter the code below. If you did not receive a validation
            code, we can attempt to send it again:

            <Button size="sm" onClick={() => this.props.handleResend(email)} style={{marginLeft: '5px'}}>
            {resendWorking
              ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Sending...</Fragment>
              : "Re-Send Code"
            }
            </Button>

          </p>

          <Form.Group>
            <Form.Label>Validation Code</Form.Label>
            <Form.Control required type="text" onChange={(e) => this.setState({verificationCode: e.target.value})} />
            <Form.Control.Feedback type="invalid">
              Please provide a validation code.
            </Form.Control.Feedback>
          </Form.Group>
          { error &&
            <Alert variant="danger">{error}</Alert>
          }
          {
            resendError &&
            <Alert variant="danger">{resendError}</Alert>
          }
          {
            resendSuccess &&
            <Alert variant="success">Validation code re-sent.</Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
          <Button variant="secondary" onClick={onLogout} disabled={working}>Log Out</Button>
          <Button type="submit" variant="primary" disabled={working}>
            {working
              ? <Fragment><Spinner as="span" animation="border" size="sm" style={{marginRight: '10px'}} />Verifying...</Fragment>
              : "Verify"
            }
          </Button>
          </div>
        </Modal.Footer>
        </Form>
      </Modal>
    )
  }

}

export default VerifyEmailModal